import config from '../config';

export const prepareBookingData = (data, collectFromHome, serviceKey) => {
  let bookingData = {};
  let totalPrice = 0;
  const selectedProducts = [];

  if (serviceKey === 'madeToMeasure' || serviceKey === 'Repair') {
    for (const [key, value] of Object.entries(data.values)) {
      selectedProducts.push({
        label: value.name,
        quantity: 1,
        value: value.value,
      });

      bookingData = {
        serviceKey: serviceKey || false,
        selectedProducts,
      };
    }
  } else {
    if (data.length > 0) {
      data.forEach(category => {
        let typeSum = 0;
        const itemTypes = [];

        category.types.forEach(type => {
          typeSum += Number(type.price);
          itemTypes.push(type.itemName);
        });

        let finalProductPrice = Number(category.count) * Number(typeSum);
        selectedProducts.push({
          ...(!serviceKey && { index: category.index || false }),
          lable: category.heading,
          key: category.heading,
          quantity: category.count,
          items: itemTypes,
          itemTotalPrice: finalProductPrice,
        });
        totalPrice += finalProductPrice;
      });

      bookingData = {
        serviceKey: serviceKey || false,
        itemsTotal: totalPrice,
        totalPrice: calculationFinalPrice(totalPrice, collectFromHome),
        selectedProducts,
        collectFromHome,
      };
    }
  }
  return bookingData;
};

export const calculationFinalPrice = (totalPrice, collectFromHome) => {
  let collectionFromHomePrice = collectFromHome ? config.collectFromHomeCharge : 0;
  return (
    parseFloat(totalPrice) + prepareServicePrice(totalPrice) + parseFloat(collectionFromHomePrice)
  );
};

// method created only for m2m
export const totalEnteredPriceByMaker = (totalPrice, serviceFee, collectFromHome) => {
  let collectionFromHomePrice = collectFromHome ? config.collectFromHomeCharge : 0;
  return parseFloat(totalPrice) - (serviceFee + parseFloat(collectionFromHomePrice));
};

export const prepareServicePrice = totalPrice => {
  let finalPrice = 0;
  if (totalPrice <= 100) {
    finalPrice = (totalPrice * 20) / 100;
  } else if (totalPrice > 100) {
    finalPrice = 20;
  }
  return parseFloat(finalPrice);
};

export const calculateDiscount = (totalPrice, discountPercentage) => {
  let discountedPrice = 0;
  let finalPrice = 0;
  discountedPrice = (totalPrice * discountPercentage) / 100;
  finalPrice = totalPrice - discountedPrice;
  return finalPrice;
};
