import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { ValidationError } from '../../components';
import LocationAutocompleteInputImpl from './LocationAutocompleteInputImpl.js';
import _ from 'lodash';
import config from '../../config';

class LocationAutocompleteInputComponent extends Component {
  constructor() {
    super();
    this.showPopup = false;
  }
  render() {
    /* eslint-disable no-unused-vars */
    const { rootClassName, labelClassName, ...restProps } = this.props;
    const { input, label, meta, valueFromForm, ...otherProps } = restProps;
    /* eslint-enable no-unused-vars */
    let showPopup = false;
    try {
      if (valueFromForm != undefined) {
        if (_.has(valueFromForm, ['selectedPlace', 'address'])) {
          let result = valueFromForm.selectedPlace.distance;
          if (result != undefined) {
            let check = result;
            // popup and message will handle differently for maker and user
            let showPopUpAndMessage = false;
            if (_.has(this.props, 'formType') && this.props.formType === 'maker') {
              showPopUpAndMessage = true;
            }
            if (check < config.maps.search.currentLocationBoundsDistance ||
              valueFromForm.selectedPlace.edinburghDistance < config.maps.search.currentLocationBoundsDistance) {
              this.showPopup = false;
              localStorage.setItem('newCategory', 'no');
              this.props.showMessage(showPopUpAndMessage);
            } else {
              localStorage.setItem('newCategory', true);
              this.showPopup = !showPopUpAndMessage;
              this.props.showMessage(false);
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value;
    const locationAutocompleteProps = { label, meta, ...otherProps, input: { ...input, value } };
    const labelInfo = label ? (
      <label className={labelClassName} htmlFor={input.name}>
        {label}
      </label>
    ) : null;

    return (
      <div>
        <div className={rootClassName}>
          {labelInfo}
          <LocationAutocompleteInputImpl {...locationAutocompleteProps} />
        </div>
        <div>
          <ValidationError
            location={this.showPopup ? false : true}
            showPopup={this.showPopup}
            fieldMeta={meta}
          />
        </div>
      </div>
    );
  }
}

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  type: null,
  label: null,
};

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
};

export default LocationAutocompleteInputImpl;

export const LocationAutocompleteInputField = props => {
  return <Field component={LocationAutocompleteInputComponent} {...props} />;
};
