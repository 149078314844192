// added discount code
export const discountData = {
  discountCodes: [
    {
      name: 'FARFETCH20',
      percentage: 20,
    },
    {
      name: "AXELARIGATO20",
      percentage: 20,
    },
    {
      name: 'VIP20',
      percentage: 20,
    },
    {
      name: 'TEAANDTEQUILA20',
      percentage: 20,
    },
    {
      name: 'MAYAMIKO20',
      percentage: 20,
    },
    {
      name: 'WHERING20',
      percentage: 20,
    },
    {
       name: 'SYW20',
       percentage: 20,
    },
    {
      name:'HAYHILL20',
      percentage:20
    },
    {
      name:'ILKANDERNIE20',
      percentage:20
    },
    {
      name:'LDC20',
      percentage:20
    },
    {
      name:'ByRotation20',
      percentage:20
    },
    {
      name:'NAVYGREY20',
      percentage:20
    },
    {
      name:'STITCHMAS20',
      percentage:20
    },
    {
      name:'BIANCA20',
      percentage:20
    },
    {
      name:'KERRY20',
      percentage:20
    },
    {
      name:'SOMETHINGBORROWED20',
      percentage:20
    },
    {
      name:'OXWASH20',
      percentage:20
    },
    {
      name:'NTH20',
      percentage:20
    },
    {
      name:'WELCOME20',
      percentage:20
    },
    {
      name:'MARYS20',
      percentage:20
    },
    {
      name:'SPRING20',
      percentage:20
    },
    {
      name: 'THESEAMVIP',
      percentage: 20,
    },
    {
      name: 'SPLENDIDSTITCHES20',
      percentage: 20,
    },
    {
      name: 'HELLOAGAIN',
      percentage: 20,
    },
    {
      name: 'ALLBRIGHT20',
      percentage: 20,
    },
    {
      name: '69BVIP',
      percentage: 20,
    },
    {
      name: 'KTO20',
      percentage: 20,
    },
    {
      name: 'RETOLD20',
      percentage: 20,
    },
    {
      name: 'DANIELLE20',
      percentage: 20,
    }
  ],
};
