import React from 'react';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';
import moment from 'moment';
import { LINE_ITEM_NIGHT, DATE_TYPE_DATE, propTypes } from '../../util/types';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import { get } from 'lodash';
import css from './BookingBreakdown.module.css';

const BookingPeriod = props => {
  const { startDate, endDate, dateType, bookingData, transaction} = props;
  const preference = localStorage.getItem('preference');
  const makerName = localStorage.getItem('makerName');
  const maker = makerName !== null ?  makerName : get(transaction, 'provider.attributes.profile.displayName', '') ;
  // set preference text
  let preferenceText = 'Visit studio';
  switch (preference) {
    case 'HomeCollection':
      preferenceText = 'Home collection';
      break;
    case 'post':
      preferenceText = 'Via post';
      break;
    case 'inStore':
      preferenceText = 'Visit store';
      break;
    default:
      preferenceText;
      break;
  }
  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          {/* <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingStart" />
          </div> */}
          <div className={css.headingLabel}>
            Your Project
          </div>
          <div className={css.dayInfo}>
            {/* <FormattedDate value={startDate} {...dateFormatOptions} /> */}
            <FormattedMessage id="BookingBreakdown.collectionsDetails" />
          </div>
          <div className={css.dayInfo}>
            {/* <FormattedDate value={startDate} {...timeFormatOptions} /> */}
            <p className={css.paraleft}>
               Date : <span className={css.rightValue}><FormattedDate value={endDate} {...dateFormatOptions} /></span>
            </p>
            <p className={css.paraleft}>
              Preference :  <span className={css.rightValue}>{preferenceText !== null ? preferenceText : ''}</span>
            </p>
            <p className={css.paraleft} >
               Maker : <span className={css.rightValue}>{maker}</span>
            </p><br/>
            <span>
              {/* {bookingData && bookingData.serviceKey === 'madeToMeasure'
                ? 'Item'
                : 'Item * Quantity'} */}
              Order Details
            </span>
          </div>
        </div>

        <div className={css.bookingPeriodSectionRigth}>
          {/* <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingEnd" />
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={endDate} {...dateFormatOptions} />
          </div> */}
          <div className={css.dayInfo}>
            {/* <br/><br/><br/> 
            <span>
              {bookingData && bookingData.serviceKey === 'madeToMeasure' ? 'Quantity' : 'Price'}
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, bookingData, transaction } = props;

  const attributes = booking ? booking.attributes : bookingData;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings

  let lStartDate;
  let lEndDate;
  if (bookingData && bookingData.bookingDates && bookingData.serviceKey === 'madeToMeasure') {
    lStartDate = bookingData.bookingDates.bookingStart;
    lEndDate = bookingData.bookingDates.bookingEnd;
  } else {
    const { start, end, displayStart, displayEnd } = attributes;
    lStartDate = displayStart || start;
    lEndDate = displayEnd || end;
  }

  const localStartDate = dateFromAPIToLocalNoon(lStartDate);
  const localEndDateRaw = dateFromAPIToLocalNoon(lEndDate);

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const endDay = isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

  return (
    <>
      <div className={css.lineItem}>
        <BookingPeriod
          startDate={localStartDate}
          endDate={endDay}
          dateType={dateType}
          transaction={transaction}
          bookingData={bookingData}
        />
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
