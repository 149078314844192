import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingAdditionalSkillsForm } from '../../forms';
import { ListingLink } from '..';

import config from '../../config';
import { findOptionsForSelectFilter, findPriceForSelectFilterOptions } from '../../util/search';

import css from './EditListingAdditionalSkillsPanel.module.css';

const FEATURES_NAME = 'additionalSkills';

const EditListingAdditionalSkillsPanel = props => {
  const { custom } = config;
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingAdditionalSkillsPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingAdditionalSkillsPanel.createListingTitle" />
  );

  const initialAdditionalSkills = publicData && publicData.additionalSkills;
  const { contactToHire } = publicData;
  const additionalSkills =
    initialAdditionalSkills &&
    initialAdditionalSkills.map(skill => {
      return skill.key;
    });

  const initialValues = { additionalSkills, contactToHire };

  const options = findOptionsForSelectFilter('additionalSkills', custom.filters);

  return (
    <div className={classes}>
      <h1 className={css.h1}>{panelTitle}</h1>
      <span className={css.smallSpan}>
        A little more information will help us match you with the perfect customers. You can select
        an unlimited number of boxes on this page.
      </span>
      <EditListingAdditionalSkillsForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        onSubmit={values => {
          const { additionalSkills = [], contactToHire = 'no', gender } = values;

          findPriceForSelectFilterOptions(options, additionalSkills).then(additionalSkills => {
            const updatedValues = {
              publicData: { additionalSkills, contactToHire, gender },
            };
            onSubmit(updatedValues);
          });
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingAdditionalSkillsPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingAdditionalSkillsPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAdditionalSkillsPanel;
