import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  AddImages,
  Button,
  Form,
  ValidationError,
  ExternalLink,
  FieldRadioButton,
  FieldCheckbox,
} from '../../components';

import css from './EditListingInsuranceForm.module.css';

const ACCEPT_IMAGES = 'image/*';

const CreataInsuranceTitle = () => {
  return (
    <div>
      <p className={css.CreataInsuranceTitle}>
        That’s fine! With the help of our official Insurance partner we have created an exclusive
        insurance policy for Makers at The Seam. <br />
        Cover details:
        <br />
        • £10 per month, cancel at anytime via email or phone.
        <br />
        • Equipment protection up to £10,000 (theft, loss or damage)
        <br />
        • Public Liability cover up to £1,000,000
        <br />
        • Professional indemnity cover up to £100,000 (covers you for professional mistakes or
        errors)
        <br />
        <br />
        Follow the link below to purchase your policy, when asked for your occupation, select “Maker
        at The Seam”. Once you’ve purchased your insurance policy, come back to us and complete your
        profile.
      </p>
    </div>
  );
};

export class EditListingInsuranceFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
      disableSecondInputImage: true,
      renderFrom: false,
      renderLink: false,
      toggleForm: false,
      checkedUploadInsurance: false,
      checkedCreateInsurance: false,
      submitDisabled: true,
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler(file) {
    if (file) {
      this.setState({
        imageUploadRequested: true,
      });
      this.disableSecondInputImagefun(false);
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({
            imageUploadRequested: false,
          });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  disableSecondInputImagefun = value => {
    this.setState({
      disableSecondInputImage: value,
    });
  };

  onChangeValue(event) {
    this.setState({ submitDisabled: true });
    if (event.target.value == 'yes') {
      this.setState({
        renderFrom: true,
        renderLink: false,
      });
    } else {
      this.setState({
        renderLink: true,
        renderFrom: false,
      });
    }
  }

  onChangeHandler = event => {
    if (event.target.checked) {
      this.setState({ submitDisabled: false });
    } else {
      this.setState({ submitDisabled: true });
    }
  };

  toggleChangeUploadInsurance = event => {
    this.setState({ checkedUploadInsurance: event.target.checked });
  };

  toggleChangeCreateInsurance = event => {
    this.setState({ checkedCreateInsurance: event.target.checked });
  };

  render() {
    const { insurance } = this.props.initialValues;
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        disableSecondInputImagefun={this.disableSecondInputImagefun}
        imageUploadRequested={this.state.imageUploadRequested}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            disableSecondInputImagefun,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          // submitDisabled = invalid || disabled || submitInProgress || imageUploadRequested || ready;

          const classes = classNames(css.root, className);

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}
              <h2 className={css.insuranceHeading}>Do you have insurance ?</h2>
              <h2 className={css.insuranceHeading}>
                Your policy must cover you for Public Liability and Professional Indemnity
              </h2>
              <FieldRadioButton
                id="insuranceYes"
                name="insurance"
                label="Yes"
                value="yes"
                checked={insurance && insurance === 'yes'}
                onClick={event => this.onChangeValue(event)}
              />
              <FieldRadioButton
                id="insuranceNo"
                name="insurance"
                label="No"
                value="no"
                checked={insurance && insurance === 'no'}
                onClick={event => this.onChangeValue(event)}
              />
              {this.state.renderFrom && (
                <div>
                  <h2>Please send a copy of your insurance policy to our team for review</h2>
                  <p>
                    You can email your documents to 
                    <a href="mailto:support@theseam.uk">
                      <span style={{ color: 'blue' }}>support@theseam.uk</span>
                    </a>
                  </p>
                  {/* <div className={css.checkboxBelowStyle}> */}
                  {/* <input
                      className={css.input}
                      type="checkbox"
                      defaultChecked={this.state.checkedUploadInsurance}
                      onChange={this.toggleChangeUploadInsurance}
                    /> */}
                  <FieldCheckbox
                    id="insurance"
                    name="insurance"
                    label="By checking this box I agree to notify The Seam with any future changes to my insurance policy, this includes changes to the type of cover. If I decide to not renew the insurance policy, I understand that I must notify The Seam before the policy end date. I understand that I must have valid insurance cover before accepting any jobs from customers of The Seam."
                    value="no"
                    onChangeHandler={this.onChangeHandler}
                  />
                  {/* <p>
                      By checking this box I confirm that I have provided a copy of my insurance
                    </p> */}
                  {/* </div> */}
                </div>
              )}
              {this.state.renderLink && (
                <div>
                  <CreataInsuranceTitle />
                  <ExternalLink
                    className={css.linkStyle}
                    href="https://www.createinsurance.co.uk/the-seam/"
                  >
                    Click here to purchase your insurance policy
                  </ExternalLink>
                  <br />
                  <br />
                  {/* <div className={css.checkboxBelowStyle}> */}
                  {/* <input
                      className={css.input}
                      type="checkbox"
                      defaultChecked={this.state.checkedCreateInsurance}
                      onChange={this.toggleChangeCreateInsurance}
                    /> */}
                  <FieldCheckbox
                    id="insurance"
                    name="insurance"
                    label="By ticking this box, I confirm that I have purchased The Seam insurance policy
                       via Create Insurance"
                    value="yes"
                    onChangeHandler={this.onChangeHandler}
                  />
                  {/* <p>
                      By ticking this box, I confirm that I have purchased The Seam insurance policy
                      via Create Insurance
                    </p> */}
                  {/* </div> */}
                </div>
              )}
              {uploadImageFailed}
              {publishListingFailed}
              {showListingFailed}

              <br />
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={this.state.submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingInsuranceFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingInsuranceFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingInsuranceFormComponent);
