import React from 'react';
import classNames from 'classnames';
import { getPlacePredictions, getPlaceDetails, locationBounds } from '../../util/googleMaps';
import { userLocation } from '../../util/maps';
import config from '../../config';

import css from './LocationAutocompleteInput.module.css';

export const CURRENT_LOCATION_ID = 'current-location';

// When displaying data from the Google Maps Places API, and
// attribution is required next to the results.
// See: https://developers.google.com/places/web-service/policies#powered
export const GeocoderAttribution = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.poweredByGoogle, className);
  return <div className={classes} />;
};

/**
 * A forward geocoding (place name -> coordinates) implementation
 * using the Google Maps Places API.
 */
class GeocoderGoogleMaps {
  constructor() {
    this.sessionToken = null;
  }
  getSessionToken() {
    this.sessionToken =
      this.sessionToken || new window.google.maps.places.AutocompleteSessionToken();
    return this.sessionToken;
  }

  // Public API
  //

  /**
   * Search places with the given name.
   *
   * @param {String} search query for place names
   *
   * @return {Promise<{ search: String, predictions: Array<Object>}>}
   * results of the geocoding, should have the original search query
   * and an array of predictions. The format of the predictions is
   * only relevant for the `getPlaceDetails` function below.
   */
  getPlacePredictions(search) {
    // let temp = locationBounds(
    //   { lat: 51.5073509, lng: -0.1277583 },
    //   config.maps.search.currentLocationBoundsDistance
    // );
    const center = { lat: 51.5074, lng: -0.1278 };
    const limitCountriesMaybe = {
      componentRestrictions: {
        country: ['uk'],
      },
      types: ['(regions)'],
      origin: { lat: 51.5074, lng: -0.1278 },
      strictBounds: true,
      bounds: {
        east: 0.016569356750094633,
        north: 51.59718242841195,
        south: 51.41751937158805,
        west: -0.2720859567500946,
      },
    };

    return getPlacePredictions(search, this.getSessionToken(), limitCountriesMaybe)
      .then(results => {
        return {
          search,
          predictions: results.predictions,
        };
      })
      .catch(error => {
        console.log(error);
      });
  }

  /**
   * Get the ID of the given prediction.
   */
  getPredictionId(prediction) {
    if (prediction.predictionPlace) {
      // default prediction defined above
      return prediction.id;
    }
    // prediction from Google Maps Places API
    return prediction.place_id;
  }

  /**
   * Get the address text of the given prediction.
   */
  getPredictionAddress(prediction) {
    if (prediction.predictionPlace) {
      // default prediction defined above
      return prediction.predictionPlace.address;
    }
    // prediction from Google Maps Places API
    return prediction.description;
  }

  /**
   * Fetch or read place details from the selected prediction.
   *
   * @param {Object} prediction selected prediction object
   *
   * @return {Promise<util.propTypes.place>} a place object
   */
  getPlaceDetails(prediction) {
    if (this.getPredictionId(prediction) === CURRENT_LOCATION_ID) {
      return userLocation().then(latlng => {
        return {
          address: '',
          origin: latlng,
          bounds: locationBounds(latlng, config.maps.search.currentLocationBoundsDistance),
        };
      });
    }

    if (prediction.predictionPlace) {
      return Promise.resolve(prediction.predictionPlace);
    }

    return getPlaceDetails(prediction.place_id, this.getSessionToken())
      .then(place => {
        this.sessionToken = null;
        place.distance = prediction.distance_meters;
        return place;
      })
      .catch(error => {
        console.log(error);
      });
  }
}

export default GeocoderGoogleMaps;
