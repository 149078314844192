/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const { Money } = sdkTypes;

const LineItemUnknownItemsMaybeAlt = props => {
  const { transaction, unitType, intl, bookingData, type } = props;

  let itenary = null;

  if (type && type === 'MadeToMeasure') {
    itenary = bookingData.map(prod => {
      return (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <span>
              {prod.label}
              {prod.quantity} X
            </span>
          </span>
        </div>
      );
    });
  } else {
    itenary = bookingData.selectedProducts.map(prod => {
      return (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <span>
              {prod.lable} {prod.quantity} X
            </span>
            <p className={css.para}>{prod.items.toString()}</p>
          </span>
          <span style={{ marginRight: '-2px' }} className={css.itemValue}>
            £{prod.itemTotalPrice}
          </span>
        </div>
      );
    });
  }

  return itenary;
};

LineItemUnknownItemsMaybeAlt.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybeAlt;
