import React from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { CustomerCollectionForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

import css from './CustomerCollectionPanel.module.css';

class CustomerCollectionPanel extends React.Component {
  onFormSubmit = async values => {
    const { updateOwnDraftListing, onCreateTransaction } = this.props;

    // const draftListingId = new UUID('6075be1f-7e87-4784-9fda-e3d935595d3e');
    // await updateOwnDraftListing({
    //   id: draftListingId,
    //   publicData: { collectionDate: '05/02/1993' },
    // });

    // await onCreateTransaction({ listingId: draftListingId, collectionDate: '05/02/1993' });
  };

  render() {
    const {
      className,
      rootClassName,
      listing,
      availability,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const defaultAvailabilityPlan = {
      type: 'availability-plan/day',
      entries: [
        { dayOfWeek: 'mon', seats: 1 },
        { dayOfWeek: 'tue', seats: 1 },
        { dayOfWeek: 'wed', seats: 1 },
        { dayOfWeek: 'thu', seats: 1 },
        { dayOfWeek: 'fri', seats: 1 },
        { dayOfWeek: 'sat', seats: 1 },
        { dayOfWeek: 'sun', seats: 1 },
      ],
    };
    const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;

    return (
      <div className={classes}>
        <h1 className={css.title}>Collection Date</h1>
        <CustomerCollectionForm
          className={css.form}
          listingId={currentListing.id}
          initialValues={{ availabilityPlan }}
          availability={availability}
          availabilityPlan={availabilityPlan}
          onSubmit={this.onFormSubmit}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateError={errors.updateListingError}
          updateInProgress={updateInProgress}
        />
      </div>
    );
  }
}

CustomerCollectionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

CustomerCollectionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  availability: shape({
    calendar: object.isRequired,
    onFetchAvailabilityExceptions: func.isRequired,
    onCreateAvailabilityException: func.isRequired,
    onDeleteAvailabilityException: func.isRequired,
  }),
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default CustomerCollectionPanel;
