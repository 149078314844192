import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form, FieldRadioButton } from '../../components';

import css from './EditListingSpecialistSkillsForm.module.css';

const selectedValue = [];
const EditListingSpecialistSkillsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
      } = formRenderProps;

      const { initialValues } = props;

      if (selectedValue.length < 1 && initialValues && initialValues.specialistSkills) {
        initialValues.specialistSkills.map(item => {
          selectedValue.push({ name: 'specialistSkills', value: item });
        });
      }
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('specialistSkills', filterConfig);

      const [formData, setData] = useState([]);
      const [disabledSubmitButton, setButtonValue] = useState(true);

      const checkValue = e => {
        if (selectedValue.length > 0) {
          let flag = false;
          selectedValue.map((item, i) => {
            if (e.target.value === item.value) {
              flag = true;
              if (e.target.value) {
                selectedValue.splice(i, 1);
              }
            }
          });
          if (flag === false && selectedValue.length < 5) {
            selectedValue.push({ name: e.target.name, value: e.target.value });
          }
        } else if (selectedValue.length < 5) {
          selectedValue.push({ name: e.target.name, value: e.target.value });
        }

        setData(selectedValue);
        if (formData.length > 0) {
          setButtonValue(false);
        } else {
          setButtonValue(true);
        }
      };

      return (
        <Form className={classes} onSubmit={handleSubmit} onChange={checkValue}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            className={css.features}
            id={name}
            name={name}
            options={options}
            maxSelect={5}
            iniCount={selectedValue.length}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={disabledSubmitButton}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingSpecialistSkillsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingSpecialistSkillsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingSpecialistSkillsForm = EditListingSpecialistSkillsFormComponent;

export default EditListingSpecialistSkillsForm;
