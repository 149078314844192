import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Projects.module.css';

import altrationImage from './images/altration.png';
// import restoreationImage from './images/restoreation.png';
import mToMImage from './images/madeToMeasure.png';
class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: '',
      showNewCategory: 'no'
    };
  }

  onProjectClick = e => {
    e.preventDefault();
    this.setState({
      projectName: e.currentTarget.value,
    });
    const { onSelectProject } = this.props;
    if (e.currentTarget.value !== '') {
      //const serviceValue = e.currentTarget.value === 'Repair' ? 'madeToMeasure' : e.currentTarget.value ;
      onSelectProject(e.currentTarget.value);
    }
  };

  componentWillMount() {
    try {
      const show = localStorage.getItem('newCategory');
      console.log('show', show);
      this.setState({ showNewCategory: show });
    } catch (error) {
      console.log('\n\n\n[error][Projects]===========',error );
    }
  }

  render() {
    const { projectName, showNewCategory } = this.state;
    console.log('[showNewCategory]', showNewCategory)
    let borderAlteration = false;
    let borderRepair = false;
    let borderMade = false;
    if (projectName == 'altration') {
      borderAlteration = true;
    } else if (projectName == 'restoration') {
      borderRepair = true;
    } else if (projectName == 'madeToMeasure') {
      borderMade = true;
    } else if (projectName == 'Repair'){
      borderRepair = true;
    } else {}
    const { rootClassName, className } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const selectedClass = sp => {
      return selectedProject === sp ? css.selectedBorder : '';
    };

    return (
      <div className={css.row}>
        {showNewCategory === 'no' &&
          <div className={css.colum}>
          <button
            className={borderAlteration ? css.buttonBorder : css.location}
            value="altration"
            onClick={this.onProjectClick}
          >
            <div className={css.imageWrapper}>
              <div className={css.aspectWrapper}>
                <img src={altrationImage} alt={altrationImage} className={css.locationImage} />
              </div>
            </div>
            <div className={`${css.linkText}`}>
              <span className={css.locationName}>Alterations to size or fit</span>
              <ul>
                <li>
                  <i>&nbsp;</i>
                  <span>Clear + fixed pricing</span>
                </li>
                <li>
                  <i>&nbsp;</i>
                  <span>Instant booking confirmation</span>
                </li>
              </ul>
            </div>
          </button>
        </div>
        }
        {showNewCategory === 'no'  &&
          <div className={css.colum}>
          <button
            className={borderMade ? css.buttonBorder : css.location}
            value="madeToMeasure"
            onClick={this.onProjectClick}
          >
            <div className={css.imageWrapper}>
              <div className={css.aspectWrapper}>
                <img src={mToMImage} alt="madeToMeasure" className={css.locationImage} />
              </div>
            </div>
            <div className={css.linkText}>
              <span className={css.locationName}>Repairs + Custom Projects</span>
              <ul>
              <li>
                  <i>&nbsp;</i>
                  <span>Price upon request</span>
                </li>
                <li>
                  <i>&nbsp;</i>
                  <span>One-to-one advice before booking</span>
                </li>
              </ul>
            </div>
          </button>
        </div>
        }
        {showNewCategory === 'true' && 
           <div className={css.colum}>
           <button
             className={borderRepair ? css.buttonBorder : css.location}
             value="Repair"
             onClick={this.onProjectClick}
           >
             <div className={css.imageWrapper}>
               <div className={css.aspectWrapper}>
                 <img src={mToMImage} alt="Repairs" className={css.locationImage} />
               </div>
             </div>
             <div className={css.linkText}>
               <span className={css.locationName}>Repairs (UK wide)</span>
               <ul>
               <li>
                   <i>&nbsp;</i>
                   <span>Price upon request</span>
                 </li>
                 <li>
                   <i>&nbsp;</i>
                   <span>One-to-one advice before booking</span>
                 </li>
               </ul>
             </div>
           </button>
         </div>
        }        
      </div>
    );
  }
}

Projects.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

Projects.propTypes = {
  rootClassName: string,
  className: string,
};

export default Projects;
