import React, { useState, useEffect } from 'react';

import { Modal, Button } from '../../components';

import css from './CustomerServiceForm.module.css';

const ItemTypeModal = props => {
  const {
    itemSelected,
    onItemAdd,
    setSelectedItem,
    isItemModal,
    setBookingData,
    setItemModal,
  } = props;

  const [count, setCount] = useState(1);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const onClickItemAdd = () => {
    if (selectedTypes.length > 0 && count > 0) {
      props.setBookingData({
        index: Math.floor(Math.random() * 100 + 1),
        heading: itemSelected.label,
        count: count,
        types: selectedTypes,
      });
      setItemModal(false);
    }
  };

  const onCheckBoxClick = (input, price) => {
    const { checked, value, name } = input.currentTarget;
    if (checked) {
      // console.log('checked---------', checked);
      const clickedOpt = {
        itemName: name,
        price: value,
      };
      if (selectedTypes.length > 0) {
        const preSelectedOpt = selectedTypes;
        preSelectedOpt.push(clickedOpt);
        setSelectedTypes(preSelectedOpt);
      } else {
        setSelectedTypes([clickedOpt]);
      }

      // console.log('selectedTypes after check', selectedTypes);
    } else {
      // console.log('unched------------');
      if (selectedTypes.length > 0) {
        const remainingOpt = selectedTypes.filter(opt => opt.itemName !== name);
        //  console.log('remaining optiions in uncheck', remainingOpt);
        if (remainingOpt.length > 0) {
          setSelectedTypes(remainingOpt);
        } else {
          setSelectedTypes([]);
        }
        //  console.log('selectedTypes in uncheck', selectedTypes);
      }
    }
  };

  return (
    <Modal
      id="Page.ItemModal"
      isOpen={isItemModal}
      onClose={() => props.setItemModal(false)}
      onManageDisableScrolling={() => {}}
    >
      <section>{itemSelected && <p className={css.modalHeading}>{itemSelected.label}</p>}</section>
      <form className={css.termsWrapper} id="itemTypeForm">
        {/* <p>What alterations and repairs you would like made to this item?</p> */}
        {itemSelected &&
          itemSelected.type.map(item => (
            <label className={css.container}>
              <span style={{ marginLeft: '10px' }}>{item.label}</span>
              <input
                className={css.checkboxInput}
                type="checkbox"
                id={item.key}
                name={item.key}
                value={item.price}
                onChange={onCheckBoxClick}
              />
              <span className={css.checkmark}></span>
            </label>
          ))}
        {/* <div style={{ padding: '10px', marginLeft: '30px' }}>
          <button
            className={css.roundedBtn}
            type="button"
            onClick={() => {
              if (count >= 1) {
                setCount(count - 1);
              }
            }}
          >
            -
          </button>
          <span style={{ padding: '15px' }}>{count}</span>
          <button
            className={css.roundedBtn}
            type="button"
            onClick={() => {
              setCount(count + 1);
            }}
          >
            +
          </button>
        </div> */}
        <Button
          className={css.button}
          type="button"
          onClick={() => {
            onClickItemAdd();
          }}
        >
          Add
        </Button>
      </form>
    </Modal>
  );
};

export default ItemTypeModal;
