import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import LocationSearchForm from '../../forms/LocationSearchForm/LocationSearchForm';
import Button from '../Button/Button';
import ModalComponent from '../Modal/Modal';
import EditListingLocationForLanding from '../../forms/EditListingLocationForm/EditListingForLanding';
import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  const modalContent = (
    <div>
      <span className={css.locationName}>We’re not there yet</span>
      <div>
        <span>But we’re working on it! We can send you an email when we get there</span>
      </div>
      <Button children="Email me" className={css.emailMe} />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          {/* <FormattedMessage id="SectionHero.subTitle" /> */}
        </h2>
        {/* <NamedLink
          name="SearchPage"
          to={{
            search:
              'address=Finland&bounds=70.0922932%2C31.5870999%2C59.693623%2C20.456500199999937',
          }}
          className={css.heroButton}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink> */}
        {/* <NamedLink className={css.heroButton} name="CustomerPage" params={{ tab: 'description' }}>
          <span className={css.createListing}>
            <FormattedMessage id="SectionHero.browseButton" />
          </span>
        </NamedLink> */}
        {/* <FieldTextInput id="101" label="search" /> */}
        <p className={css.landingAddress} style={{marginTop:"0px",marginBottom:"0px"}}>Enter your address to begin</p>
        <div className={css.locationSearch}>
          <EditListingLocationForLanding
            className={css.form}
            onSubmit={value => {
              console.log('submit==========', value);
            }}
            saveActionMsg="Saved"
            ready={0}
            updated={0}
            updateInProgress={0}
          />

          {/* <LocationSearchForm
            onSubmit={value => {
              console.log('submit form-----', value.location.search);
            }}
            rootClassName={css.search}
          />
          <Button children="Find Makers" className={css.button} /> */}
          {/* <ModalComponent
            id="modalShow"
            onClose={() => {
              console.log('close modal');
            }}
            isOpen={1}
            children={modalContent}
            onManageDisableScrolling={() => {
              console.log('manage');
            }}
            containerClassName={css.modal}
          /> */}
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
