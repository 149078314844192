import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, Button, FieldSelect } from '../../components';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './EditListingProjectForm.module.css';

export const EditListingProjectFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        capacityOptions,
      } = formRenderProps;

      const capacityPlaceholder = intl.formatMessage({
        id: 'EditListingProjectForm.quantityPlaceholder',
      });

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCapacityForm.updateFailed" />
        </p>
      ) : null;

      const capacityRequired = required(
        intl.formatMessage({
          id: 'EditListingCapacityForm.quantityRequired',
        })
      );

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { selectProjectData, showForm } = props;

      if (showForm) {
        return <div>Please wait ....</div>;
      } else {
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            <div className={css.gridContainer}>
              <div className={css.gridItem}>
                <h1 className={css.heading}>{selectProjectData.key1.heading}</h1>
              </div>
              <div className={css.gridItem}>
                  <p>Price</p>
              </div>
              <div className={css.gridItem}>
                  <p>Quantity</p>
              </div>
              <div className={css.gridItem}>
                {selectProjectData.key1.subheading.map(subheading1 => {
                  return <div className={css.subheading}>{subheading1}</div>;
                })}
              </div>
              <div className={css.gridItem}>
                {selectProjectData.key1.price.map(price1 => {
                  return <div className={css.price}>{price1}</div>;
                })}
              </div>
              <div className={css.gridItem}>
                {selectProjectData.key1.subheading.map(value => {
                  return (
                    <div className={css.quantity}>
                      <FieldSelect
                        className={css.capacity}
                        name={`${value}`}
                        id={`${value}`}
                        validate={capacityRequired}
                      >
                        <option value="">{capacityPlaceholder}</option>
                        {selectProjectData.key1.quantity.map(c => (
                          <option key={c.key} value={c.key}>
                            {c.label}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  );
                })}
              </div>
              <div className={css.gridItem}>
                <h1 className={css.heading}>{selectProjectData.key2.heading}</h1>
              </div>
              <div className={css.gridItem}>{}</div>
              <div className={css.gridItem}>{}</div>
              <div className={css.gridItem}>
                {selectProjectData.key2.subheading.map(subheading2 => {
                  return <div className={css.subheading}>{subheading2}</div>;
                })}
              </div>
              <div className={css.gridItem}>
                {selectProjectData.key2.price.map(price2 => {
                  return <div className={css.price}>{price2}</div>;
                })}
              </div>
              <div className={css.gridItem}>
                {selectProjectData.key2.subheading.map(value => {
                  return (
                    <div className={css.quantity}>
                      <FieldSelect
                        className={css.capacity}
                        name={`${value}`}
                        id={`${value}`}
                        validate={capacityRequired}
                      >
                        <option value="">{capacityPlaceholder}</option>
                        {selectProjectData.key2.quantity.map(c => (
                          <option key={c.key} value={c.key}>
                            {c.label}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  );
                })}
              </div>
            </div>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }
    }}
  />
);

EditListingProjectFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingProjectFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  capacityOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingProjectFormComponent);
