import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { prepareBookingData } from '../../util/bookingDataHelper';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingAvailabilityPanel,
  CustomerProjectPanel,
  CustomerServicePanel,
  CustomerMakerPanel,
  CustomerPaymentPanel,
  CustomerCollectionPanel,
  EditListingSpecialistSkillsPanel,
  EditListingAdditionalSkillsPanel,
  EditListingLocationPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
} from '../../components';

import _ from 'lodash';

import css from './CustomerWizard.module.css';

// export const AVAILABILITY = 'availability';
export const DESCRIPTION = 'description';
export const SERVICE = 'service';
export const MAKER = 'maker';
export const PAYMENT = 'payment';
export const COLLECTION = 'collection';
export const SPECIALISTSKILLS = 'specialistSkills';

export const ADDITIONALSKILLS = 'additionalSkills';
// export const POLICY = 'policy';
export const LOCATION = 'location';
// export const PRICING = 'pricing';
export const PHOTOS = 'photos';
import { customerData as serviceConfig } from '../CustomerServicePanel/config';
import { selectProject } from '../../containers/CustomerPage/CustomerPage.duck';

const redirectToMakerListingFromCustomer = listing => {
  const url = `/l/${listing.title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')}/${
    listing.listId
  }`;
  window.location.href = url;
};

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  DESCRIPTION,
  SERVICE,
  MAKER,
  PAYMENT,
  // SPECIALISTSKILLS,
  // ADDITIONALSKILLS,
  // POLICY,
  // LOCATION,
  //PRICING,
  // AVAILABILITY,
  // PHOTOS,
];

const pathParamsToNextTab = (tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return nextTab;
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (tab, marketplaceTabs, history) => {
  // const currentPathParams = {
  //   ...params,
  //   type: LISTING_PAGE_PARAM_TYPE_DRAFT,
  //   id: listingId,
  // };
  // const routes = routeConfiguration();

  // // Replace current "new" path to "draft" path.
  // // Browser's back button should lead to editing current draft instead of creating a new one.
  // if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
  //   const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
  //   history.replace(draftURI);
  // }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(tab, marketplaceTabs);
  // const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  // console.log('nextPathParams---------', nextPathParams);
  history.push(nextPathParams);
};

const CustomerWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    availability,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    updatedTab,
    updateInProgress,
    onSelectProject,
    selectedProject,
    intl,
    queryMakerListingsForCustomerPage,
    onManageDisableScrolling,
    submitSignup,
    isAuthenticated,
    submitLogin,
    authInProgress,
    isSignupModal,
    setSignupModal,
    updateOwnDraftListing,
    onCreateTransaction,
    loginError,
    signupError,
    setBookingData,
    setCollectFromHome,
    bookingData,
    collectFromHome,
    isItemModal,
    setItemModal,
    deleteBookingItem,
    setPreparedBookingData,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const onCompleteEditListingWizardTab = (tab, updateValues) => {
    try {
      if ('project' in updateValues) {
        localStorage.setItem('project', JSON.stringify(updateValues.project));
      }

      if ('publicData' in updateValues) {
        const { serviceKey } = updateValues.publicData || false;
        if (serviceKey && serviceKey === 'madeToMeasure' || serviceKey === 'Repair' ) {
          const preparedData =
            !_.isEmpty(updateValues.publicData) &&
            prepareBookingData(
              updateValues.publicData,
              updateValues.publicData.collectFromHome,
              updateValues.publicData.serviceKey
            );
          localStorage.setItem('bookingData', JSON.stringify(preparedData));
        } else {
          const preparedData =
            !_.isEmpty(bookingData) &&
            prepareBookingData(
              bookingData,
              updateValues.publicData.collectFromHome,
              updateValues.publicData.serviceKey
            );

          if (!_.isEmpty(preparedData)) {
            localStorage.setItem('bookingData', JSON.stringify(preparedData));
          }

          const bookingTocustomerServices = {
            publicData: {
              values: bookingData,
              serviceKey: serviceKey,
            },
          };

          localStorage.setItem('customerServices', JSON.stringify(bookingTocustomerServices));
        }
      }
    } catch (e) {
      console.log('error in [onCompleteEditListingWizardTab]', e);
    }

    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };
    redirectAfterDraftUpdate(tab, marketplaceTabs, history);
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
    };
  };

  // let tabnew = 'specialistSkills';
  // tab = 'specialistSkills';
  switch (tab) {
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <CustomerProjectPanel
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSelectProject={onSelectProject}
          selectedProject={selectedProject}
          setPreparedBookingData={setPreparedBookingData}
          setCollectFromHome={setCollectFromHome}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case SERVICE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewService'
        : 'EditListingWizard.saveEditDescription';
      return (
        <CustomerServicePanel
          {...panelProps(SERVICE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          selectedProject={selectedProject}
          onManageDisableScrolling={onManageDisableScrolling}
          submitSignup={submitSignup}
          isAuthenticated={isAuthenticated}
          submitLogin={submitLogin}
          authInProgress={authInProgress}
          isSignupModal={isSignupModal}
          setSignupModal={setSignupModal}
          loginError={loginError}
          signupError={signupError}
          setBookingData={setBookingData}
          setCollectFromHome={setCollectFromHome}
          bookingData={bookingData}
          collectFromHome={collectFromHome}
          setItemModal={setItemModal}
          isItemModal={isItemModal}
          deleteBookingItem={deleteBookingItem}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }

    case MAKER: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <CustomerMakerPanel
          {...panelProps(MAKER)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          queryMakerListingsForCustomerPage={queryMakerListingsForCustomerPage}
          onManageDisableScrolling={onManageDisableScrolling}
          onCreateListingDraft={onCreateListingDraft}
          submitSignup={submitSignup}
          history={history}
          isAuthenticated={isAuthenticated}
          onSubmit={values => {
            redirectToMakerListingFromCustomer(values);
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case COLLECTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <CustomerCollectionPanel
          {...panelProps(COLLECTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          updateOwnDraftListing={updateOwnDraftListing}
          onCreateTransaction={onCreateTransaction}
        />
      );
    }
    case PAYMENT: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'CustomerListingWizard.payment'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <CustomerPaymentPanel
          {...panelProps(PAYMENT)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case SPECIALISTSKILLS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingSpecialistSkillsPanel
          {...panelProps(SPECIALISTSKILLS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }

    case ADDITIONALSKILLS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAdditionalSkills'
        : 'EditListingWizard.saveEditAdditionalSkills';
      return (
        <EditListingAdditionalSkillsPanel
          {...panelProps(ADDITIONALSKILLS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          {...panelProps(LOCATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      return (
        <EditListingPhotosPanel
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }
    default:
      return null;
  }
};

CustomerWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
};

const { array, bool, func, object, oneOf, shape, string } = PropTypes;

CustomerWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,
  availability: object.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};

export default CustomerWizardTab;
