import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form, FieldRadioButton } from '../../components';

import css from './EditListingAdditionalSkillsForm.module.css';

const EditListingAdditionalSkillsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
      } = formRenderProps;

      const { contactToHire } = props.initialValues;
      let [error, setError] = useState(false);
      let [contactHire, setContactHire] = useState('');

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const [gender, setGenderMaker] = useState('');

      const setGender = e => {
        let gender = e.target.value;
        setGenderMaker(gender);
      };

      const handleHire = e => {
        let contactHire = e.target.value;
        setContactHire(contactHire);
      };

      function handleError() {
        setError(true);
      }

      const options = findOptionsForSelectFilter('additionalSkills', filterConfig);
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup className={css.features} id={name} name={name} options={options} />

          <span className={css.smallSpan}>
            Sometimes The Seam works with UK based manufacturers, helping them to hire additional
            support when they need it most. Are you interested in being contacted by businesses who
            are looking to hire for full-time or contract positions?
          </span>

          <FieldRadioButton
            id="contactToHireYes"
            name="contactToHire"
            label="Yes"
            value="yes"
            onClick={handleHire}
            checked={contactHire === 'yes'}
          />
          <FieldRadioButton
            id="contactToHireNo"
            name="contactToHire"
            label="No"
            value="no"
            onClick={handleHire}
            checked={contactHire === 'no'}
          />

          <br />
          <h2 className={css.errorGender}>What gender do you identify as?</h2>
          {error && <p className={css.errorGender}>Choose a gender</p>}
          <FieldRadioButton
            id="male"
            name="gender"
            label="male"
            required
            value="male"
            checked={gender == 'male'}
            onClick={setGender}
          />
          <FieldRadioButton
            id="female"
            name="gender"
            required
            label="female"
            value="female"
            checked={gender == 'female'}
            onClick={setGender}
          />
          <FieldRadioButton
            id="non binary"
            name="gender"
            required
            label="non binary"
            value="non binary"
            checked={gender == 'non binary'}
            onClick={setGender}
          />
          <br />
          <Button
            className={css.submitButton}
            type="submit"
            onClick={() => handleError()}
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingAdditionalSkillsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingAdditionalSkillsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingAdditionalSkillsForm = EditListingAdditionalSkillsFormComponent;

export default EditListingAdditionalSkillsForm;
