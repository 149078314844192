/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'capacity',
    label: 'Capacity',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_capacity'],
    config: {
      options: [
        { key: '1to3', label: '1 to 3' },
        { key: '4to6', label: '4 to 6' },
        { key: '7to9', label: '7 to 9' },
        { key: '10plus', label: '10 plus' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'a friend', label: 'A friend' },
        { key: 'instagram', label: 'Instagram' },
        { key: 'google search', label: 'Google search' },
        { key: 'newspaper', label: 'Newspaper' },
        { key: 'university', label: 'University' },
        { key: 'podcast', label: 'Podcast' },
        { key: 'facebook', label: 'Facebook' },
        { key: 'flyer', label: 'Flyer' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'specialistSkills',
    label: 'Specialist Skills',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_specialistSkills'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // {
        //   key: 'Casual Clothing repair + alter size',
        //   label: 'Casual Clothing repair + alter size',
        //   price: 100,
        // },
        // {
        //   key: 'Casual Clothing made-to-measure',
        //   label: 'Casual Clothing made-to-measure',
        //   price: 101,
        // },
        // {
        //   key: 'Suit repair + alter size',
        //   label: 'Suit repair + alter size',
        //   price: 102,
        // },
        // {
        //   key: 'Suit made-to-measure',
        //   label: 'Suit made-to-measure',
        //   price: 103,
        // },
        // {
        //   key: 'Evening dress repair + alter size',
        //   label: 'Evening dress repair + alter size',
        //   price: 104,
        // },
        // {
        //   key: 'Evening dress made-to-measure ',
        //   label: 'Evening dress made-to-measure ',
        //   price: 105,
        // },
        // {
        //   key: 'Bride or Bridesmaid dress repair + alter size ',
        //   label: 'Bride or Bridesmaid dress repair + alter size ',
        //   price: 106,
        // },
        // {
        //   key: 'Bride or Bridesmaid dress made to measure ',
        //   label: 'Bride or Bridesmaid dress made to measure ',
        //   price: 107,
        // },
        // {
        //   key: 'Lingerie made-to-measure',
        //   label: 'Lingerie made-to-measure',
        //   price: 108,
        // },
        // {
        //   key: 'Swimwear made-to-measure',
        //   label: 'Swimwear made-to-measure',
        //   price: 109,
        // },
        // {
        //   key: 'Activewear repair + alter size',
        //   label: 'Activewear repair + alter size',
        //   price: 110,
        // },
        // {
        //   key: 'Activewear made-to-measure',
        //   label: 'Activewear made-to-measure',
        //   price: 111,
        // },

        // {
        //   key: 'Coat or Jacket repair + alter size ',
        //   label: 'Coat or Jacket repair + alter size ',
        //   price: 112,
        // },
        // {
        //   key: 'Coat or Jacket made-to-measure',
        //   label: 'Coat or Jacket made-to-measure',
        //   price: 113,
        // },
        // {
        //   key: 'Costume repair + alter size ',
        //   label: 'Costume repair + alter size ',
        //   price: 114,
        // },
        // {
        //   key: 'Costume made-to-measure',
        //   label: 'Costume made-to-measure ',
        //   price: 115,
        // },
        // {
        //   key: 'Bag restoration + repair (non leather)',
        //   label: 'Bag restoration + repair (non leather)',
        //   price: 116,
        // },
        // {
        //   key: 'Leather Bag, Shoes or Boots restoration + repair',
        //   label: 'Leather Bag, Shoes or Boots restoration + repair',
        //   price: 117,
        // },
        // {
        //   key: 'Trainers restoration + repair',
        //   label: 'Trainers restoration + repair',
        //   price: 118,
        // },
        // {
        //   key: 'Curtains made-to-measure',
        //   label: 'Curtains made-to-measure',
        //   price: 119,
        // },
        // {
        //   key: 'Sofa/Chair cover made-to-measure',
        //   label: 'Sofa/Chair cover made-to-measure',
        //   price: 120,
        // },
        // {
        //   key: 'Cushion covers/blankets made-to-measure',
        //   label: 'Cushion covers/blankets made-to-measure',
        //   price: 121,
        // },
        {
          key: 'Alter/Repair Everyday Clothes (e.g casual dress, trousers, top)',
          label: 'Alter/Repair Everyday Clothes (e.g casual dress, trousers, top)',
          price: 100,
        },
        {
          key: 'Alter/Repair Outerwear (e.g Coat or jacket)',
          label: 'Alter/Repair Outerwear (e.g Coat or jacket)',
          price: 101,
        },
        {
          key: 'Alter/Repair Tailored Suits (e.g blazer, waistcoat, trousers)',
          label: 'Alter/Repair Tailored Suits (e.g blazer, waistcoat, trousers)',
          price: 102,
        },
        {
          key: 'Alter/Repair Bridal + Evening Dress',
          label: 'Alter/Repair Bridal + Evening Dress',
          price: 103,
        },
        {
          key: 'Alter/Repair Lingerie + Swimwear',
          label: 'Alter/Repair Lingerie + Swimwear',
          price: 104,
        },
        {
          key: 'Made to Measure Everyday Clothes (e.g casual dress, trousers, top) ',
          label: 'Made to Measure Everyday Clothes (e.g casual dress, trousers, top) ',
          price: 105,
        },
        {
          key: 'Made to Measure Outerwear (e.g Coat or jacket)',
          label: 'Made to Measure Outerwear (e.g Coat or jacket)',
          price: 105,
        },
        {
          key: 'Made to Measure Tailored Suits (e.g blazer, waistcoat, trousers)',
          label: 'Made to Measure Tailored Suits (e.g blazer, waistcoat, trousers)',
          price: 106,
        },
        {
          key: 'Made to Measure Bridal + Evening Dress',
          label: 'Made to Measure Bridal + Evening Dress',
          price: 106,
        },
        {
          key: 'Made to Measure Lingerie + Swimwear',
          label: 'Made to Measure Lingerie + Swimwear',
          price: 107,
        },
        {
          key: 'Made to Measure Knitwear (by hand or machine)',
          label: 'Made to Measure Knitwear (by hand or machine)',
          price: 107,
        },
        {
          key: 'Made to Measure Sportswear + Technical',
          label: 'Made to Measure Sportswear + Technical',
          price: 107,
        },
        {
          key: 'Bespoke Customisations Embroidery (by hand or machine)',
          label: 'Bespoke Customisations Embroidery (by hand or machine)',
          price: 107,
        },
        {
          key: 'Bespoke Customisations Colour dying techniques',
          label: 'Bespoke Customisations Colour dying techniques',
          price: 107,
        },
        {
          key: 'Bespoke Customisations Specialist repair projects (e.g delicate materials)',
          label: 'Bespoke Customisations Specialist repair projects (e.g delicate materials)',
          price: 107,
        },
        {
          key: 'Bespoke Customisations Re-work (transform or restyle garments)',
          label: 'Bespoke Customisations Re-work (transform or restyle garments)',
          price: 107,
        },
      ],
    },
  },
  {
    id: 'additionalSkills',
    label: 'Additional Info',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_additionalSkills'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'sampleMaking',
          label: 'Sample Making',
          price: 100,
        },
        {
          key: 'patternCutting',
          label: 'Pattern Cutting',
          price: 101,
        },
        {
          key: 'experiencewithIndustrialFlatbed',
          label: 'Experience with Industrial Flatbed',
          price: 100,
        },
        {
          key: 'Experience with Industrial Overlocker',
          label: 'Experience with Industrial Overlocker',
          price: 101,
        },
        {
          key: 'Have access to an overlocker',
          label: 'Have access to an overlocker',
          price: 101,
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
