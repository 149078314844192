import React, { Component } from 'react';
import { string, bool, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './SendMessageForm.module.css';
import sendIcon from './image/iconsend.png';

const IconSendMessage = () => {
  return <img src={sendIcon} alt="no image" className={css.locationImage} />;
};

class ImageAttachmentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  componentDidMount() {
    const fileField = document.getElementById(this.props.id);
    if (fileField) {
      fileField.addEventListener('change', this.handleFileChange);
    }
  }

  onClick = () => {
    const fileField = document.getElementById(this.props.id);
    if (fileField) {
      fileField.click();
    }
  };

  fileToDataUri = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  onChange = file => {
    const { setDataUri } = this.props;
    if (!file) {
      setDataUri('');
      return;
    }

    this.fileToDataUri(file).then(dataUri => {
      setDataUri(dataUri);
    });
  };

  handleFileChange = event => {
    if (event.target.files.length > 0) {
      // for now, only allows 1 image to be sent at a time
      this.setState({ message: '1 file selected' });
    } else {
      this.setState({ message: '' });
    }
    // console.log(this.state.image);
  };

  render() {
    const { id } = this.props;
    return (
      <div className={css.formIconContainer}>
        <div className={css.iconContainer} onClick={this.onClick}>
          <IconSendMessage />
        </div>
        <input
          className={css.inputTypeFile}
          type="file"
          accept="image/png, image/jpeg"
          id={id}
          onChange={event => this.onChange(event.target.files[0] || null)}
        />
      </div>
    );
  }
}

ImageAttachmentComponent.defaultProps = {
  id: '',
};

ImageAttachmentComponent.propTypes = {
  id: string,
  // from injectIntl
  intl: intlShape.isRequired,
};

const ImageAttachment = compose(injectIntl)(ImageAttachmentComponent);

ImageAttachment.displayName = 'ImageAttachment';

export default ImageAttachment;
