import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
//import { EditListingDescriptionForm } from '../../forms';

import CheckoutPage from '../../containers/CheckoutPage/CheckoutPage';

import { compose } from 'redux';
import { connect } from 'react-redux';

import config from '../../config';
import { PaymentMethodsForm } from '../../forms';
import { ensureCurrentUser, ensureStripeCustomer, ensurePaymentMethodCard } from '../../util/data';
import { createStripeSetupIntent, stripeCustomer } from './PaymentMethodsPage.duck.js';
import { handleCardSetup } from '../../ducks/stripe.duck';
import { savePaymentMethod, deletePaymentMethod } from '../../ducks/paymentMethods.duck';

import css from './CustomerPaymentPanel.module.css';
import ProjectDetails from './ProjectDetails';

const CustomerPaymentPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    deletePaymentMethodInProgress,
    onCreateSetupIntent,
    onHandleCardSetup,
    onSavePaymentMethod,
    onDeletePaymentMethod,
    fetchStripeCustomer,
    scrollingDisabled,
    onManageDisableScrolling,
    intl,
    stripeCustomerFetched,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = params => {
    setIsSubmitting(true);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const stripeCustomer = ensuredCurrentUser.stripeCustomer;
    const { stripe, card, formValues } = params;

    const getPaymentParams = (currentUser, formValues) => {
      const { name, addressLine1, addressLine2, postal, state, city, country } = formValues;
      const addressMaybe =
        addressLine1 && postal
          ? {
              address: {
                city: city,
                country: country,
                line1: addressLine1,
                line2: addressLine2,
                postal_code: postal,
                state: state,
              },
            }
          : {};
      const billingDetails = {
        name,
        email: ensureCurrentUser(currentUser).attributes.email,
        ...addressMaybe,
      };

      const paymentParams = {
        payment_method_data: {
          billing_details: billingDetails,
        },
      };

      return paymentParams;
    };

    const getClientSecret = setupIntent => {
      return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
    };

    onCreateSetupIntent()
      .then(setupIntent => {
        const stripeParams = {
          stripe,
          card,
          setupIntentClientSecret: getClientSecret(setupIntent),
          paymentParams: getPaymentParams(currentUser, formValues),
        };
        return onHandleCardSetup(stripeParams);
      })
      .then(result => {
        const newPaymentMethod = result.setupIntent.payment_method;
        // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.

        return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
      })
      .then(() => {
        // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
        fetchStripeCustomer();
        setIsSubmitting(false);
        //setCardState('default');
      })
      .catch(error => {
        console.error(error);
        setIsSubmitting(false);
      });
  };

  const handleRemovePaymentMethod = () => {
    onDeletePaymentMethod().then(() => {
      fetchStripeCustomer();
    });
  };

  //const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  return (
    <div className={classes}>
      <h1 className={css.title}>Payment</h1>
      <p className={css.aboutTitle}>You won’t be billed until your project is complete</p>
      <div className={css.formContainer}>
        <div className={css.column}>
          <PaymentMethodsForm
            //className={css.paymentForm}
            formId="PaymentMethodsForm"
            //initialValues={initalValuesForStripePayment}
            onSubmit={handleSubmit}
            handleRemovePaymentMethod={handleRemovePaymentMethod}
            //hasDefaultPaymentMethod={hasDefaultPaymentMethod}
            addPaymentMethodError={addPaymentMethodError}
            deletePaymentMethodError={deletePaymentMethodError}
            createStripeCustomerError={createStripeCustomerError}
            handleCardSetupError={handleCardSetupError}
            inProgress={isSubmitting}
          />
          {/* <CheckoutPage /> */}
        </div>
        <div className={css.column}>
          <ProjectDetails />
        </div>
      </div>
    </div>
  );
};

CustomerPaymentPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  currentUser: null,
  addPaymentMethodError: null,
  deletePaymentMethodError: null,
  createStripeCustomerError: null,
  handleCardSetupError: null,
};

CustomerPaymentPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

//export default CustomerPaymentPanel;

const mapStateToProps = state => {
  const { currentUser } = state.user;

  const {
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
  } = state.paymentMethods;

  const { stripeCustomerFetched } = state.PaymentMethodsPage;

  const { handleCardSetupError } = state.stripe;
  return {
    currentUser,
    // scrollingDisabled: isScrollingDisabled(state),
    deletePaymentMethodInProgress,
    addPaymentMethodError,
    deletePaymentMethodError,
    createStripeCustomerError,
    handleCardSetupError,
    stripeCustomerFetched,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onHandleCardSetup: params => dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params => dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onDeletePaymentMethod: params => dispatch(deletePaymentMethod(params)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CustomerPaymentPanel);
