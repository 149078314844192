import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required, composeValidators } from '../../util/validators';
import config from '../../config';
import { Form, Button, FieldSelect, Modal, ResponsiveImage } from '../../components';

import BookingBreakdown from './BookingBreakdown/BookingBreakdown';
import cardImg from './BookingBreakdown/files/cardImg.jpeg';

import ItemTypeModal from './ItemTypeModal';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './CustomerServiceForm.module.css';

export const CustomerServiceFormMadeToMeasure = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        onSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        setBookingData,
        setCollectFromHome,
        bookingData,
        collectFromHome,
        setItemModal,
        isItemModal,
        deleteBookingItem,
      } = formRenderProps;

      const [formData, setData] = useState([]);
      const [itemSelected, setSelectedItem] = useState(false);

      const onClickSubmit = () => {
        if (bookingData.length > 0) {
          props.onSubmit();
        }
      };

      const onCheckBoxClick = e => {
        const { value, checked } = e.target;
        const collectFromHome = checked ? true : false;
        props.setCollectFromHome(collectFromHome);
      };

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const { selectProjectData, showForm } = props;

      if (showForm) {
        return <div>Please wait ....</div>;
      } else {
        return (
          <Form className={classes}>
            <div className={css.row}>
              <div className={css.column}>
                <div>
                  <h1 className={css.heading}>{selectProjectData.key1.heading}</h1>
                  {selectProjectData.key1.subheading.map(subheadingObj => {
                    return (
                      <div
                        className={css.subheading}
                        onClick={() => {
                          setSelectedItem(subheadingObj);
                          setItemModal(true);
                        }}
                      >
                        {subheadingObj.label}
                      </div>
                    );
                  })}
                </div>

                <div>
                  <h1 className={css.heading}>{selectProjectData.key2.heading}</h1>
                  {selectProjectData.key2.subheading.map(subheadingObj => {
                    return (
                      <div
                        className={css.subheading}
                        onClick={() => {
                          setSelectedItem(subheadingObj);
                          setItemModal(true);
                        }}
                      >
                        {subheadingObj.label}
                      </div>
                    );
                  })}
                </div>

                <div>
                  <h1 className={css.heading}>{selectProjectData.key3.heading}</h1>
                  {selectProjectData.key3.subheading.map(subheadingObj => {
                    return (
                      <div
                        className={css.subheading}
                        onClick={() => {
                          setSelectedItem(subheadingObj);
                          setItemModal(true);
                        }}
                      >
                        {subheadingObj.label}
                      </div>
                    );
                  })}
                </div>

                <div>
                  <h1 className={css.heading}>{selectProjectData.key4.heading}</h1>
                  {selectProjectData.key4.subheading.map(subheadingObj => {
                    return (
                      <div
                        className={css.subheading}
                        onClick={() => {
                          setSelectedItem(subheadingObj);
                          setItemModal(true);
                        }}
                      >
                        {subheadingObj.label}
                      </div>
                    );
                  })}
                </div>

                <div>
                  <h1 className={css.heading}>{selectProjectData.key5.heading}</h1>
                  {selectProjectData.key5.subheading.map(subheadingObj => {
                    return (
                      <div
                        className={css.subheading}
                        onClick={() => {
                          setSelectedItem(subheadingObj);
                          setItemModal(true);
                        }}
                      >
                        {subheadingObj.label}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={css.column}>
                <div className={css.detailsContainerDesktop}>
                  <div className={css.detailsAspectWrapper}>
                    {/*<img className={css.image} src={cardImg} alt="cardImG" width="408" height="400" />*/}
                  </div>

                  <div className={css.detailsHeadings}>
                    <h2 className={css.detailsTitle}>Project Details</h2>
                  </div>
                  {isEmpty(bookingData) && <span className={css.noItem}>No Items Selected</span>}
                  {!isEmpty(bookingData) && (
                    <BookingBreakdown
                      className={css.bookingBreakdown}
                      userRole="customer"
                      unitType={config.bookingUnitType}
                      bookingData={bookingData}
                      deleteBookingItem={deleteBookingItem}
                      collectFromHome={collectFromHome}
                      page="listing"
                    />
                  )}
                  {/* {!isEmpty(bookingData) && (
                    <>
                      <label className={css.checkboxLabel}>
                        <input
                          className={css.checkboxInput}
                          id="homeCollection"
                          type="checkbox"
                          name={'homeCollection'}
                          value={false}
                          onChange={onCheckBoxClick}
                        />
                        Add Home Collection (Maker comes to me / £8 fee)
                      </label>
                    </>
                  )} */}
                </div>

                <div className={css.column}>
                  <div className={css.buttonFixed}>
                    <Button
                      className={css.submitButton}
                      type="button"
                      onClick={() => onClickSubmit()}
                      inProgress={submitInProgress}
                      ready={submitReady}
                      disabled={bookingData.length <= 0}
                    >
                      Browse Makers
                    </Button>
                  </div>  
                </div>
              </div>

              {isItemModal && (
                <ItemTypeModal
                  itemSelected={itemSelected}
                  setSelectedItem={setSelectedItem}
                  setBookingData={setBookingData}
                  bookingData={bookingData}
                  setItemModal={setItemModal}
                  isItemModal={isItemModal}
                />
              )}
            </div>
            <p style={{ marginLeft: '10px' }}>
              {' '}
              Looking for something not on this list? Email
              <a href="mailto:webmaster@example.com"> support@theseam.uk</a>
            </p>
          </Form>
        );
      }
    }}
  />
);

CustomerServiceFormMadeToMeasure.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

CustomerServiceFormMadeToMeasure.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  capacityOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(CustomerServiceFormMadeToMeasure);
