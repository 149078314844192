import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, ExternalLink } from '../../components';
import Projects from './Projects';

import css from './CustomerProjectForm.module.css';

const TITLE_MAX_LENGTH = 60;
const CONTACT_MAX_LENGTH = 10;
const ABOUT_MAX_LENGTH = 400;

const CustomerProjectFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        selectedProject,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const messageRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.aboutRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLengthAbout' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLengthAbout = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: CONTACT_MAX_LENGTH,
        }
      );

      const aboutMessage = intl.formatMessage({
        id: 'EditListingAboutForm.about',
      });

      const aboutPlaceholderMessage = intl.formatMessage({
        id: 'EditListingAboutForm.aboutPlaceholder',
      });

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const maxLength10Contact = maxLength(maxLengthAbout, CONTACT_MAX_LENGTH);
      const aboutRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.contactRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {/* {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing} */}
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <Projects
                  onSelectProject={props.onSelectProject}
                  selectedProject={selectedProject}
                />
              </div>
            </li>
            <li className={css.section}>
              <ExternalLink
                className={css.linkStyle}
                id="my_custom_link"
                key="intercom"
                href={'mailto:support@theseam.uk'}
                title={'intercome'}
              >
              Need help to get started?
          </ExternalLink>
            </li>
          </ul>
          <div className={css.fixedButton}>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={!selectedProject}
              ready={submitReady}
            >
              Next
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

CustomerProjectFormComponent.defaultProps = { className: null, fetchErrors: null };

CustomerProjectFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(CustomerProjectFormComponent);
