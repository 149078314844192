export const listing = [
  {
    title: 'Maker with New about',
    id: 1,
    img:
      'https://sharetribe.imgix.net/60485a4a-f22f-4ea9-ab99-5f4044d6a2cf%2F606aca84-147f-477f-9257-75c7687ad885?auto=format&crop=edges&fit=crop&h=120&ixlib=java-1.1.1&w=120&s=c3cdf3c2a2c43856f0362c970809bfb6',
  },
  {
    title: '222222-Maker',
    id: 2,
    img:
      'https://sharetribe.imgix.net/60485a4a-f22f-4ea9-ab99-5f4044d6a2cf%2F606b68ef-6b77-42ae-abd5-c2394920afb0?auto=format&crop=edges&fit=crop&h=120&ixlib=java-1.1.1&w=120&s=c00a642032fbf996bac5e088ed5572b5',
  },
  {
    title: 'test',
    id: 3,
    img:
      'https://sharetribe.imgix.net/60485a4a-f22f-4ea9-ab99-5f4044d6a2cf%2F606a7712-dcdb-4ea2-adef-c9f1a697f1d1?auto=format&crop=edges&fit=crop&h=120&ixlib=java-1.1.1&w=120&s=f83339ebc80d8805fc3da739bbcb380a',
  },
  // {
  //   name: 'Giorgia A.',
  //   id: 4,
  // },
  // {
  //   name: 'Mary M.',
  //   id: 5,
  // },
  // {
  //   name: 'Lucy Ann A.',
  //   id: 6,
  // },
];
