import React from 'react';
import { propTypes } from '../../../util/types';
import config from '../../../config';
import { prepareServicePrice } from '../../../util/priceCalculator';
import DeleteIcon from './DeleteIcon';
import image from './files/infoIcon.png';

import css from './BookingBreakdown.module.css';

const HomeCollect = props => {
  const { collectFromHome, bookingData, page } = props;
  let serviceFee = 0;
  if (bookingData.serviceKey === 'madeToMeasure') {
    serviceFee = bookingData.serviceFee || 0;
  } else {
    serviceFee = bookingData ? prepareServicePrice(bookingData.itemsTotal) : 0;
  }
  const homeNotcollection = 0;
  return (
    <>
      <div className={css.lineItem}>
        <span className={css.itemLabel}>
          <span className={css.tooltip}>Service Fee</span>
          <div className={css.tooltip}>
            <img className={css.image} src={image} alt="cardImG" height="13px" />
            <span className={css.tooltiptext}>
              This fee is 20% of your order (maximum £20 fee). It ensures that whilst your clothes
              are being worked on they are insured and also allows us to keep improving The Seam
              platform to make your experience as easy as possible!
            </span>
          </div>
        </span>
        <span className={css.itemValue}>£{serviceFee.toFixed(2) || 0}</span>
      </div>
      <>
        {page != 'listing' && (
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              <span>Collection Fees</span>
              <div className={css.tooltip}>
                <img className={css.image} src={image} alt="cardImG" height="13px" />
                <span className={css.tooltiptext}>
                  This fee is £8. Choose this option if you would like your Maker to visit you at
                  home or your workplace to collect your clothes and drop them off once they’re
                  ready to wear.
                </span>
              </div>
            </span>
            <span className={css.itemValue}>
              £
              {collectFromHome
                ? config.collectFromHomeCharge.toFixed(2)
                : homeNotcollection.toFixed(2)}
            </span>
          </div>
        )}
      </>
      {bookingData && bookingData.discountPrice ? (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <span>Discount</span>
            <div className={css.tooltip}>
              <img className={css.image} src={image} alt="cardImG" height="13px" />
              <span className={css.tooltiptext}>
                Discounts are available if you are visiting from one of our preferred retail
                partners. The maximum discount available is £20 per booking. wear.
              </span>
            </div>
          </span>
          <span className={css.itemValue}>-£{bookingData.discountPrice.toFixed(2)}</span>
        </div>
      ) : null}
    </>
  );
};

export default HomeCollect;
