// totalPrice = total price
export const prepareServicePrice = totalPrice => {
  let finalPrice = 0;
  if (totalPrice <= 100) {
    finalPrice = (totalPrice * 20) / 100;
  } else if (totalPrice > 100) {
    finalPrice = 20;
  }
  return finalPrice;
};
// totalPrice = total price

export const getDiscountedPrice = (totalPrice, discountPercentage) => {
  let finalPrice = 0;
  let discountedPrice = 0;
  if (totalPrice <= 100) {
    discountedPrice = (totalPrice * discountPercentage) / 100;
  } else {
    discountedPrice = 20;
  }
  finalPrice = totalPrice - discountedPrice;
  return finalPrice;
};

// totalPrice = total price
export const getDiscountedPriceIndividual = (totalPrice, discountPercentage) => {
  let finalPrice = 0;
  let discountedPrice = 0;
  if (totalPrice <= 100) {
    discountedPrice = (totalPrice * discountPercentage) / 100;
  } else {
    discountedPrice = 20;
  }
  return discountedPrice;
};

// basePrice = line item price and seamCommisionPrecentage is in percentage
export const getCommisionPrice = (totalPrice, basePrice, discountedPrice) => {

  console.log('[totalPrice, basePrice, discountedPrice]', totalPrice, basePrice, discountedPrice);
  let serviceFee = 0;
  let seamCommisionPrecentage = 0;
  if (basePrice <= 100) {
    serviceFee = (basePrice * 20) / 100;
  } else if (basePrice > 100) {
    serviceFee = 20;
  } 
  if (discountedPrice > 0) {
    seamCommisionPrecentage = 0;
  } else if (basePrice <= 100 && discountedPrice === 0) {
    seamCommisionPrecentage = (serviceFee * 100 / totalPrice);
  } else if (basePrice > 100 && discountedPrice === 0) {
    seamCommisionPrecentage = (20 / totalPrice) * 100 ;
  }
  return seamCommisionPrecentage.toFixed(2);
};

