import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl, bookingData } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  let itenary;
  if (bookingData.serviceKey === 'madeToMeasure' || bookingData.serviceKey === 'Repair') {
    itenary = bookingData.selectedProducts.map(prod => {
      return (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <span>{prod.label !== 'homeCollection' && prod.label}</span>
          </span>
          <span className={css.itemValue}>{prod.label !== 'homeCollection' && prod.quantity}</span>
        </div>
      );
    });
  } else {
    itenary = bookingData.selectedProducts.map(prod => {
      return (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <span>
              {prod.lable}
              {/* * {prod.quantity}{' '} */}
            </span>
          </span>
          <span className={css.itemValue}>£{prod.itemTotalPrice.toFixed(2)}</span>
        </div>
      );
    });
  }

  return (quantity && total) || (bookingData.serviceKey === 'madeToMeasure' || bookingData.serviceKey === 'Repair') ? <>{itenary}</> : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
