/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
} from '../../../util/types';

import { prepareBookingData } from '../../../util/bookingDataHelper';

import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
// import LineItemUnitsMaybe from './LineItemUnitsMaybe';
// import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
// import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
// import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
// import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
// import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
// import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
// import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';

import css from './BookingBreakdown.module.css';
import HomeCollect from './HomeCollect';

export const BookingBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    booking,
    intl,
    dateType,
    bookingData,
    deleteBookingItem,
    collectFromHome,
    page,
    type = false,
  } = props;

  let formattedBookingData = {};
  if (!type) {
    formattedBookingData =
      bookingData.length > 0 && prepareBookingData(bookingData, collectFromHome);
  }

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <LineItemBasePriceMaybe
        bookingData={!type ? formattedBookingData : bookingData}
        deleteBookingItem={deleteBookingItem}
        type={type}
      />
      <HomeCollect
        collectFromHome={collectFromHome}
        bookingData={!type ? formattedBookingData : bookingData}
        page={page}
      />

      {!type && type !== 'MadeToMeasure' && (
        <LineItemTotalPrice
          intl={intl}
          collectFromHome={collectFromHome}
          bookingData={formattedBookingData}
        />
      )}
    </div>
  );
};

BookingBreakdownComponent.defaultProps = { rootClassName: null, className: null, dateType: null };

BookingBreakdownComponent.propTypes = {
  rootClassName: string,
  className: string,

  userRole: oneOf(['customer', 'provider']).isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  transaction: propTypes.transaction.isRequired,
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,

  // from injectIntl
  intl: intlShape.isRequired,
};

const BookingBreakdown = injectIntl(BookingBreakdownComponent);

BookingBreakdown.displayName = 'BookingBreakdown';

export default BookingBreakdown;
