import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required, composeValidators } from '../../util/validators';
import { Form, Button, FieldSelect } from '../../components';
import config from '../../config';

import BookingBreakdown from './BookingBreakdown/BookingBreakdown';
import cardImg from './BookingBreakdown/files/cardImg.jpeg';
import ItemTypeModal from './ItemTypeModal';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './CustomerServiceForm.module.css';

export const CustomerServiceFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        onSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        capacityOptions,
        bookingData,
        setBookingData,
        deleteBookingItem,
        collectFromHome,
        setCollectFromHome,
      } = formRenderProps;

      const capacityPlaceholder = intl.formatMessage({
        id: 'EditListingProjectForm.quantityPlaceholder',
      });

      const capacityRequired = required(
        intl.formatMessage({
          id: 'EditListingCapacityForm.quantityRequired',
        })
      );

      const [formData, setData] = useState([]);
      const [disabledSubmitButton, setButtonValue] = useState(true);

      const checkValue = e => {
        const selectedValue = formData;

        if (e.target.checked) {
          selectedValue.push({ name: e.target.name, value: e.target.value });
          setBookingData({
            label: e.target.name,
            quantity: e.target.value,
          });
        } else {
          if (selectedValue.length > 0) {
            selectedValue.map((item, i) => {
              if (e.target.name === item.name) {
                props.deleteBookingItem({
                  label: e.target.name,
                  type: 'mToM',
                });

                selectedValue.splice(i, 1);
              }
            });
          }
        }
        // if (selectedValue.length > 0) {
        //   let flag = false;
        //   selectedValue.map((item, i) => {
        //     if (e.target.name === item.name) {
        //       flag = true;
        //       if (e.target.value) {
        //         selectedValue[i].value = e.target.value;
        //         setBookingData({
        //           label: e.target.name,
        //           quantity: e.target.value,
        //         });
        //       } else {
        //         console.log('delet called');
        //         props.deleteBookingItem({
        //           label: e.target.name,
        //           type: 'mToM',
        //         });
        //         selectedValue.splice(i, 1);
        //       }
        //     }
        //   });
        //   if (flag === false) {
        //     selectedValue.push({ name: e.target.name, value: e.target.value });
        //     setBookingData({
        //       label: e.target.name,
        //       quantity: e.target.value,
        //     });
        //   }
        // } else {
        //   selectedValue.push({ name: e.target.name, value: e.target.value });
        //   setBookingData({
        //     label: e.target.name,
        //     quantity: e.target.value,
        //   });
        // }

        setData(selectedValue);
        if (formData.length > 0) {
          setButtonValue(false);
        } else {
          setButtonValue(true);
        }
      };

      const onCheckBoxClick = e => {
        const { value, checked } = e.target;
        const collectFromHome = checked ? true : false;
        setCollectFromHome(collectFromHome);
      };

      const handleSubmit = e => {
        e.preventDefault();
        if (bookingData.length > 0) {
          props.onSubmit(formData);
        }
      };

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { selectProjectData, showForm } = props;

      if (showForm) {
        return <div>Please wait ....</div>;
      } else {
        return (
          <Form className={classes} onSubmit={handleSubmit} onChange={checkValue}>
            <div className={css.row}>
              <div className={css.column}>
                {selectProjectData.map((item, index) => {
                  const head = (
                    <div className={css.gridItemMadeToMeasure}>
                      <div className={css.gridItem}>
                        {/* <h1 className={css.heading}>{item.heading}</h1> */}
                      </div>
                    </div>
                  );
                  const detail = item.subheading.map(value => {
                    return (
                      <div className={css.gridItemMadeToMeasure}>
                        <label className={`${css.container} ${css.padding}`}>
                          <span>{value.label}</span>
                          <input
                            className={css.checkboxInput}
                            type="checkbox"
                            id={value.key}
                            name={value.key}
                            value={value.key}
                          />
                          <span className={css.checkmark}></span>
                        </label>
                      </div>
                    );
                  });
                  return (
                    <div>
                      {head}
                      {detail}
                    </div>
                  );
                })}
              </div>
              <div className={css.column}>
                <>
                  <div className={css.detailsContainerDesktop}>
                    <div className={css.detailsAspectWrapper}>
                      {/*<img src={cardImg} alt="cardImG" width="408" height="350" />*/}
                    </div>

                    <div className={css.detailsHeadings}>
                      <h2 className={css.detailsTitle}>Project Details</h2>
                    </div>
                    {isEmpty(bookingData) && <span className={css.noItem}>No Items Selected</span>}
                    {!isEmpty(bookingData) && (
                      <BookingBreakdown
                        className={css.bookingBreakdown}
                        userRole="customer"
                        unitType={config.bookingUnitType}
                        bookingData={bookingData}
                        deleteBookingItem={deleteBookingItem}
                        collectFromHome={collectFromHome}
                        type="MadeToMeasure"
                      />
                    )}
                    {/* {!isEmpty(bookingData) && (
                      <>
                        <label className={css.checkboxLabel}>
                          <input
                            className={css.checkboxInput}
                            id="homeCollection"
                            type="checkbox"
                            name={'homeCollection'}
                            value={false}
                            onChange={onCheckBoxClick}
                          />
                          Add home-collection (Maker visits me at my place)
                        </label>
                      </>
                    )} */}
                  </div>
                </>
                <div className={css.buttonFixed}>
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    ready={submitReady}
                    disabled={disabledSubmitButton}
                  >
                    Browse Makers
                  </Button>
                </div>  
              </div>
            </div>
            <p style={{ marginLeft: '10px' }}>
              {' '}
              Looking for something not on this list? Email
              <a href="mailto:webmaster@example.com"> support@theseam.uk</a>
            </p>
          </Form>
        );
      }
    }}
  />
);

CustomerServiceFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

CustomerServiceFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  capacityOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(CustomerServiceFormComponent);
