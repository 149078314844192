import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { NamedLink } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import _ from 'lodash';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import { Form, LocationAutocompleteInputField, Button, FieldTextInput } from '../../components';

import css from './EditListingForLanding.module.css';

const identity = v => v;

export const EditListingLocationForLanding = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const [toggleValue, settoggleValue] = useState(true);

      function showMessage(value) {
        settoggleValue(value);
        // showMessage(!value);
      }
      function goToDesc(values) {
        if (_.has(values.location, ['selectedPlace', 'origin'])) {
          localStorage.setItem(
            'CusLocation:',
            JSON.stringify(values.location.selectedPlace.origin)
          );
        }
      }

      return (
        <Form className={classes} onSubmit={goToDesc(values)}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.locationSearch}>
            <LocationAutocompleteInputField
              className={css.search}
              // inputClassName={css.locationSearch}
              // iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              autoFocus
              name="location"
              label={titleRequiredMessage}
              placeholder="Enter your postcode"
              useDefaultPredictions={false}
              format={identity}
              showMessage={showMessage}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
              // toggleError={toggleError}
              toggleValue={toggleValue}
            />
            <NamedLink
              className={css.heroButton}
              name="CustomerPage"
              params={{ tab: 'description' }}
            >
              <Button
                className={css.button}
                type="submit"
                inProgress={submitInProgress}
                disabled={toggleValue}
                ready={submitReady}
              >
                Find Makers
              </Button>
            </NamedLink>
          </div>
        </Form>
      );
    }}
  />
);

EditListingLocationForLanding.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationForLanding.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationForLanding);
