import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingSpecialistSkillsForm } from '../../forms';
import { ListingLink } from '..';

import config from '../../config';
import { findOptionsForSelectFilter, findPriceForSelectFilterOptions } from '../../util/search';

import css from './EditListingSpecialistSkillsPanel.module.css';

const FEATURES_NAME = 'specialistSkills';

const EditListingSpecialistSkillsPanel = props => {
  const { custom } = config;
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  const initalSpecialistSkills = publicData && publicData.specialistSkills;

  const specialistSkills =
    initalSpecialistSkills &&
    initalSpecialistSkills.map(skill => {
      return skill.key;
    });

  const initialValues = { specialistSkills };

  const options = findOptionsForSelectFilter('specialistSkills', custom.filters);

  return (
    <div className={classes}>
      <h1 className={css.h1}>{panelTitle}</h1>
      <span className={css.smallSpan}>
      Every Maker has a unique set of skills. It’s important to differentiate yourself from other Makers and allow customers to find you based on your skills. Please select your top 5 specialist skills from the list below.
      </span>
      <EditListingSpecialistSkillsForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={initialValues}
        onSubmit={values => {
          const { specialistSkills = [] } = values;
          findPriceForSelectFilterOptions(options, specialistSkills).then(specialistSkills => {
            const updatedValues = {
              publicData: { specialistSkills },
            };
            onSubmit(updatedValues);
          });
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingSpecialistSkillsPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingSpecialistSkillsPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingSpecialistSkillsPanel;
