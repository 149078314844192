import React from 'react';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../../util/types';

import DeleteIcon from './DeleteIcon';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl, bookingData, deleteBookingItem, type } = props;
  let itenary = null;

  if (type && (type === 'MadeToMeasure' || type === 'Repair')) {
    itenary = bookingData.map(prod => {
      return (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <span>
              {prod.label !== 'homeCollection' && prod.label}
              {/* {prod.quantity} X */}
            </span>
          </span>
        </div>
      );
    });
  } else {
    itenary = bookingData.selectedProducts.map(prod => {
      const handleDelete = item => {
        deleteBookingItem(item);
      };

      return (
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            <span>
              {prod.lable} {/* {prod.quantity} X  */}
            </span>
            <p className={css.para}>{prod.items.toString()}</p>
          </span>
          <span style={{ marginRight: '-2px' }} className={css.itemValue}>
            £{prod.itemTotalPrice.toFixed(2)}
          </span>
          <div
            style={{ cursor: 'pointer', marginRight: '-5px', paddingLeft: '1px' }}
            onClick={() => handleDelete(prod)}
          >
            <DeleteIcon />
          </div>
        </div>
      );
    });
  }

  return itenary;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
