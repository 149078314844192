import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';

export class intercomComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    this.intercomInitialize();
  }

  componentDidUpdate() {
    this.intercomInitialize();
  }

  intercomInitialize = () => {
    const { currentUser } = this.props;
    let userType = '';
    if (currentUser != null) {
      userType = get(currentUser, 'attributes.profile.protectedData.userType', 'maker');
    }
    if (typeof window !== undefined) {
      if (userType === 'customer' || userType === undefined || userType === '') {
        window.Intercom('boot', {
          app_id: 'pmuu6lk0',
          custom_launcher_selector:'#my_custom_link'
        });
      } else {
        window.Intercom('shutdown');
      }
    }
  };

  render() {
    return null;
  }
}

// ProfilePageComponent.propTypes = {
//   scrollingDisabled: bool.isRequired,
//   currentUser: propTypes.currentUser,
//   user: propTypes.user,
//   userShowError: propTypes.error,
//   queryListingsError: propTypes.error,
//   listings: arrayOf(propTypes.listing).isRequired,
//   reviews: arrayOf(propTypes.review),
//   queryReviewsError: propTypes.error,

//   // form withViewport
//   viewport: shape({
//     width: number.isRequired,
//     height: number.isRequired,
//   }).isRequired,

//   // from injectIntl
//   intl: intlShape.isRequired,
// };

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    intercomInitialize: () => {
      dispatch(intercomInitialize())
    }
  }
}


const intercom = compose(connect(mapStateToProps, mapDispatchToProps))(intercomComponent);

export default intercom;
