export const customerData = {
  altration: {
    key1: {
      heading: 'Everyday Clothes',
      subheading: [
        {
          key: 'dress_jumpSuit',
          label: 'Dress, Jumpsuit',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length   (£16)', price: 16, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves     (£15)', price: 15, value: 2 },
            { key: 'Adjust Waistband', label: 'Adjust Waistband   (£15)', price: 15, value: 3 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting (taper)  (£20)', price: 20, value: 4 },
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 5 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 6,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 7,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 8 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 9 },
            { key: 'Repair Lining', label: 'Repair Lining  (£12)', price: 12, value: 10 },
            { key: 'Replace Lining', label: 'Replace Lining  (£35)', price: 35, value: 11 },
          ],
        },
        {
          key: 'trousers_skirts_shorts',
          label: 'Trousers, Skirts, Shorts',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£16)', price: 16, value: 1 },
            { key: 'Adjust Waistband', label: 'Adjust Waistband (£15)', price: 15, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£20)', price: 20, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
            { key: 'Repair Lining', label: 'Repair Lining  (£12)', price: 12, value: 9 },
            { key: 'Replace Lining', label: 'Replace Lining  (£35)', price: 35, value: 10 },
          ],
        },
        {
          key: 'jeans',
          label: 'Jeans',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£16)', price: 16, value: 1 },
            { key: 'Adjust Waistband', label: 'Adjust Waistband (£15)', price: 15, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£25)', price: 25, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£25)', price: 25, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
          ],
        },
        {
          key: 'shirt',
          label: 'Shirt',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£16)', price: 16, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves (£15)', price: 15, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£20)', price: 20, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
          ],
        },
        {
          key: 'top',
          label: 'Top',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length   (£13)', price: 13, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves     (£15)', price: 15, value: 2 },
            { key: 'Adjust Waistband', label: 'Adjust Waistband   (£15)', price: 15, value: 3 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting (taper)  (£20)', price: 20, value: 4 },
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 5 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 6,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 7,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 8 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 9 },
            { key: 'Repair Lining', label: 'Repair Lining  (£12)', price: 12, value: 10 },
            { key: 'Replace Lining', label: 'Replace Lining  (£35)', price: 35, value: 11 },
          ],
        },
        {
          key: 'lightJacket',
          label: 'Light Jacket',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£25)', price: 25, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves (£20)', price: 20, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£25)', price: 25, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£25)', price: 25, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
            { key: 'Repair Lining', label: 'Repair Lining  (£12)', price: 12, value: 9 },
            { key: 'Replace Lining', label: 'Replace Lining  (£50)', price: 50, value: 10 },
          ],
        },
        {
          key: 'sportsLeggings',
          label: 'Sports Leggings',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£10)', price: 10, value: 1 },
            { key: 'Adjust Waistband', label: 'Adjust Waistband (£10)', price: 10, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£15)', price: 15, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£10)', price: 10, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
          ],
        },
      ],
    },
    key2: {
      heading: 'Outwear',
      subheading: [
        {
          key: 'coat_jacket',
          label: 'Coat or Jacket',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£33)', price: 33, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves (£35)', price: 35, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£40)', price: 40, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£35)', price: 35, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 9 },
            { key: 'Replace Lining', label: 'Replace Lining  (£70)', price: 70, value: 10 },
          ],
        },
      ],
    },
    key3: {
      heading: 'Tailored Suits',
      subheading: [
        {
          key: 'blazer',
          label: 'Blazer',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£35)', price: 35, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves (£35)', price: 35, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£40)', price: 40, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£35)', price: 35, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£20)',
              price: 20,
              value: 6,
            },

            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 7 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 8 },
            { key: 'Replace Lining', label: 'Replace Lining  (£50)', price: 50, value: 9 },
          ],
        },
        {
          key: 'waistcoat',
          label: 'Waistcoat',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length   (£35)', price: 35, value: 1 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting (taper)  (£40)', price: 40, value: 2 },
            { key: 'Re-Hem', label: 'Re-Hem   (£35)', price: 35, value: 3 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 4,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£20)',
              price: 20,
              value: 5,
            },

            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 6 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 7 },
            { key: 'Replace Lining', label: 'Replace Lining  (£50)', price: 50, value: 8 },
          ],
        },
        {
          key: 'Trousers',
          label: 'Trousers',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£25)', price: 25, value: 1 },
            { key: 'Adjust Waistband', label: 'Adjust Waistband (£30)', price: 30, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£35)', price: 35, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£20)', price: 20, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£20)',
              price: 20,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£30)', price: 30, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 9 },
            { key: 'Replace Lining', label: 'Replace Lining  (£50)', price: 50, value: 10 },
          ],
        },
      ],
    },
    key4: {
      heading: 'Bridal & Evening',
      subheading: [
        {
          key: 'brideDress_bridesmaidDress',
          label: 'Bride or Bridesmaid dress',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£55)', price: 55, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves (£50)', price: 50, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£70)', price: 70, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£50)', price: 50, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£30)',
              price: 30,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 9 },
            { key: 'Replace Lining', label: 'Replace Lining  (£70)', price: 70, value: 10 },
          ],
        },
        {
          key: 'eveningDress',
          label: 'Evening Dress',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length (£45)', price: 45, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves (£50)', price: 50, value: 2 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting(taper) (£70)', price: 70, value: 3 },
            { key: 'Re-Hem', label: 'Re-Hem   (£50)', price: 50, value: 4 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 5,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£30)',
              price: 30,
              value: 6,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£30)', price: 30, value: 7 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 8 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 9 },
            { key: 'Replace Lining', label: 'Replace Lining  (£70)', price: 70, value: 10 },
          ],
        },
      ],
    },
    key5: {
      heading: 'Lingerie & Swimwear',
      subheading: [
        {
          key: 'swimwear_lingerie_nightwear',
          label: 'Swimwear, Lingerie or Nightwear',
          type: [
            { key: 'Adjust Length', label: 'Adjust Length   (£15)', price: 15, value: 1 },
            { key: 'Adjust Sleeves', label: 'Adjust Sleeves     (£15)', price: 15, value: 2 },
            { key: 'Adjust Waistband', label: 'Adjust Waistband   (£15)', price: 15, value: 3 },
            { key: 'Adjust Fitting', label: 'Adjust Fitting (taper)  (£20)', price: 20, value: 4 },
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 5 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 6,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 7,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 8 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 9 },
          ],
        },
      ],
    },
  },
  restoration: {
    key1: {
      heading: 'Everyday Clothes',
      subheading: [
        {
          key: 'dress_jumpSuit',
          label: 'Dress, Jumpsuit',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
            { key: 'Repair Lining', label: 'Repair Lining  (£12)', price: 12, value: 6 },
            { key: 'Replace Lining', label: 'Replace Lining  (£35)', price: 35, value: 7 },
          ],
        },
        {
          key: 'trousers_skirts_shorts',
          label: 'Trousers, Skirts, Shorts',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
            { key: 'Repair Lining', label: 'Repair Lining  (£12)', price: 12, value: 6 },
            { key: 'Replace Lining', label: 'Replace Lining  (£35)', price: 35, value: 7 },
          ],
        },
        {
          key: 'jeans',
          label: 'Jeans',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£25)', price: 25, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
          ],
        },
        {
          key: 'shirt',
          label: 'Shirt',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
          ],
        },
        {
          key: 'top',
          label: 'Top',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
            { key: 'Repair Lining', label: 'Repair Lining  (£12)', price: 12, value: 6 },
            { key: 'Replace Lining', label: 'Replace Lining  (£35)', price: 35, value: 7 },
          ],
        },
        {
          key: 'lightJacket',
          label: 'Light Jacket',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£25)', price: 25, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
            { key: 'Repair Lining', label: 'Repair Lining  (£12)', price: 12, value: 6 },
            { key: 'Replace Lining', label: 'Replace Lining  (£50)', price: 50, value: 7 },
          ],
        },
        {
          key: 'sportsLeggings',
          label: 'Sports Leggings',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£10)', price: 10, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
          ],
        },
      ],
    },
    key2: {
      heading: 'Outwear',
      subheading: [
        {
          key: 'coat_jacket',
          label: 'Coat or Jacket',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£35)', price: 35, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 6 },
            { key: 'Replace Lining', label: 'Replace Lining  (£70)', price: 70, value: 7 },
          ],
        },
      ],
    },
    key3: {
      heading: 'Tailored Suits',
      subheading: [
        {
          key: 'blazer',
          label: 'Blazer',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£35)', price: 35, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£20)',
              price: 20,
              value: 3,
            },

            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 4 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 5 },
            { key: 'Replace Lining', label: 'Replace Lining  (£50)', price: 50, value: 6 },
          ],
        },
        {
          key: 'waistcoat',
          label: 'Waistcoat',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£35)', price: 35, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£20)',
              price: 20,
              value: 3,
            },

            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 4 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 5 },
            { key: 'Replace Lining', label: 'Replace Lining  (£50)', price: 50, value: 6 },
          ],
        },
        {
          key: 'Trousers',
          label: 'Trousers',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£20)', price: 20, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£20)',
              price: 20,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£30)', price: 30, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 6 },
            { key: 'Replace Lining', label: 'Replace Lining  (£50)', price: 50, value: 7 },
          ],
        },
      ],
    },
    key4: {
      heading: 'Bridal & Evening',
      subheading: [
        {
          key: 'brideDress_bridesmaidDress',
          label: 'Bride or Bridesmaid dress',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£50)', price: 50, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£30)',
              price: 30,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 6 },
            { key: 'Replace Lining', label: 'Replace Lining  (£70)', price: 70, value: 7 },
          ],
        },
        {
          key: 'eveningDress',
          label: 'Evening Dress',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£50)', price: 50, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£20)',
              price: 20,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£30)',
              price: 30,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£30)', price: 30, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
            { key: 'Repair Lining', label: 'Repair Lining  (£30)', price: 30, value: 6 },
            { key: 'Replace Lining', label: 'Replace Lining  (£70)', price: 70, value: 7 },
          ],
        },
      ],
    },
    key5: {
      heading: 'Lingerie & Swimwear',
      subheading: [
        {
          key: 'swimwear_lingerie_nightwear',
          label: 'Swimwear, Lingerie or Nightwear',
          type: [
            { key: 'Re-Hem', label: 'Re-Hem   (£15)', price: 15, value: 1 },
            {
              key: 'Repair Small Hole or Tear',
              label: 'Repair Small Hole or Tear  (£10)',
              price: 10,
              value: 2,
            },
            {
              key: 'Repair Large Hole or Tear',
              label: 'Repair Large Hole or Tear  (£15)',
              price: 15,
              value: 3,
            },
            { key: 'Replace a Zip', label: 'Replace a Zip  (£20)', price: 20, value: 4 },
            { key: 'Replace a Button', label: 'Replace a Button  (£3)', price: 3, value: 5 },
          ],
        },
      ],
    },
  },

  madeToMeasure: [
    {
      heading: 'Wool or silk garments',
      subheading: [
        { key: 'Hole repair (including moth-eaten holes)', label: 'Hole repair (including moth-eaten holes)' },
        { key: 'Tear repairs', label: 'Tear repairs' },
      ],
    },
    {
      heading: 'Bespoke Customisations',
      subheading: [
        { key: 'Embroidery (by hand and machine)', label: 'Embroidery (by hand and machine)' },
        { key: 'Colour dying techniques', label: 'Colour dying techniques' },
        {
          key: 'Specialist repair projects (e.g. delicate materials)',
          label: 'Specialist repair projects (e.g. delicate materials)',
        },
        {
          key: 'Re-work (transform or restyle garments)',
          label: 'Re-work (transform or restyle garments)',
        },
      ],
    },
    {
      heading: 'Made to measure',
      subheading: [
        {
          key: 'Everyday clothes (e.g. dress, jumpsuit, trousers)',
          label: 'Everyday clothes (e.g. dress, jumpsuit, trousers)',
        },
        { key: 'Bridal + Evening wear', label: 'Bridal + Evening wear' },
        { key: 'Tailored Suits', label: 'Tailored Suits' },
        { key: 'Outerwear (e.g. coats, jackets)', label: 'Outerwear (e.g. coats, jackets)' },
        { key: 'Lingerie + Swimwear', label: 'Lingerie + Swimwear' },
        { key: 'Knitwear (by hand or machine)', label: 'Knitwear (by hand or machine)' },
        { key: 'Sportswear + Technical', label: 'Sportswear + Technical' },
      ],
    },
  ],
  Repair: [
    {
      heading: 'Wool or silk garments',
      subheading: [
        { key: 'Hole repair (including moth-eaten holes)', label: 'Hole repair (eg.moth-eaten holes, nicks in fabric)' },
        { key: 'Tear repairs', label: 'Tear repairs (eg.rip or seam split)' },
      ],
    },
    {
      heading: 'Bespoke Customisations',
      subheading: [
        { key: 'Re-hem', label: 'Re-hem' },
        { key: 'Zip replacement', label: 'Zip replacement' },
        {
          key: 'Button replacement',
          label: 'Button replacement',
        },
        {
          key: 'Replace lining (eg.coat, blazer, dress)',
          label: 'Replace lining (eg.coat, blazer, dress)',
        },
      ],
    },
    {
      heading: 'Made to measure',
      subheading: [
        {
          key: 'Embroidery (by hand or machine, eg. initials, logo)',
          label: 'Embroidery (by hand or machine, eg. initials, logo)',
        },
        { key: 'Color dying techniques', label: 'Color dying techniques' },
        { key: 'Sportswear + gym kits', label: 'Sportswear + gym kits' },
        { key: 'Something else not, listed here', label: 'Something else not, listed here' },
      ],
    },
  ],
  
};
