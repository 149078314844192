import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Projects.module.css';
import _ from 'lodash';

import altrationImage from './images/altration.png';
import restoreationImage from './images/restoreation.png';
import mToMImage from './images/madeToMeasure.png';
import { func } from 'prop-types';

class MakersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDistance: true
    }
  }

  onProjectClick = (e, value) => {
    e.preventDefault();
    const { selectedListItem } = this.props;
    if (e.currentTarget.value !== '') {
      selectedListItem(value);
    }
  };

  showHomeCollection = () => {
    localStorage.setItem('showHomeCollection', true);
  }

  componentWillMount() {
    try {
      const value = localStorage.getItem('newCategory');
      if (value != null && value === 'true') {
        this.setState({
          showDistance : false
        })
      }
    } catch (error) {

    }
  }

  render() {
    const { rootClassName, className, makers } = this.props;
    const { showDistance } = this.state;
    const classes = classNames(rootClassName || css.root, className);
    const newMakers = _.sortBy(makers, 'distance');
    // filter maker based on distance less than 5 or 5 km
    const filterList = newMakers.filter(maker => maker.distance === 3.10 || maker.distance < 3.10);
    // if maker exists in filter list 
    if (filterList.length > 0) {
      this.showHomeCollection();
    }
    return (
      <div className={classes}>
        <div className={css.makerList}>
          {newMakers &&
            newMakers.map(item => {
              return (
                // display only those maker's that are screened(manually) from flex-console (maker's publicData)
                item.isScreened && (
                  <button
                    className={css.makerItem}
                    value={item.listId}
                    onClick={e => {
                      this.onProjectClick(e, { title: item.title, listId: item.listId });
                    }}
                    tabindex="0"
                  >
                    <div className={css.profileImageContainer}>
                      <img src={item.imageUrl} alt={item.imageUrl} className={css.profileImage} />
                    </div>
                    <div className={css.profileDetailContainer}>
                      <p className={css.locationName}>{item.displayName}</p>
                      <p className={css.locationName}>
                        { showDistance && (item.distance ? item.distance + ' miles' : '0.1 miles')  
                        }
                        {' '}
                      </p>
                    </div>
                  </button>
                )
              );
            })}
        </div>
      </div>
    );
  }
}

MakersList.defaultProps = { rootClassName: null, className: null, makers: [] };

const { string, array } = PropTypes;

MakersList.propTypes = {
  rootClassName: string,
  className: string,
  makers: array,
  selectedListItem: func.isRequired,
};

export default MakersList;
