import React, { useState, useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { find, get } from 'lodash';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { NamedRedirect } from '../../components';
import { ListingLink, Modal, Button } from '../../components';
import { SignupForm } from '../../forms';
import config from '../../config';
import { listing } from './configList';
import ManageListingCard from '../ManageListingCard/ManageListingCard';
import MakersList from '../../forms/CustomerProjectForm/MakersList';
const CURRENCY = config.currency;

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';

import css from './CustomerMakerPanel.module.css';
import defaultAvatar from './default_avatar.png';

class CustomerMakerPanel extends React.Component {
  state = {
    makers: [],
    //     makerTitle: '',
    //     makerId: '',
    disabledButton: true,
    selectedMaker: null,
  };

  async componentDidMount() {
    const { queryMakerListingsForCustomerPage } = this.props;
    const res = await queryMakerListingsForCustomerPage();

    const { lat, lng } = JSON.parse(localStorage.getItem('CusLocation:'));

    let cusLngLat = new google.maps.LatLng(lng, lat);

    const makersList = [];
    // filtering makers data
    res.data.data.forEach(list => {
      const userId = list.relationships.author.data.id.uuid;
      const mkrLoc = list.attributes.geolocation || false;
      const makerLatlong = new google.maps.LatLng(mkrLoc.lng, mkrLoc.lat);
      const distance = mkrLoc
        ? google.maps.geometry.spherical.computeDistanceBetween(makerLatlong, cusLngLat)
        : false;

      const userImageObj = find(res.data.included, { type: 'user', id: { uuid: userId } });
      const imageId = get(userImageObj, 'relationships.profileImage.data.id.uuid');
      //const imageId = get(list, 'relationships.images.data[0].id.uuid');
      const imageObj = find(res.data.included, { type: 'image', id: { uuid: imageId } });
      const imageUrl = get(imageObj, 'attributes.variants.original.url', defaultAvatar);
      const included = find(res.data.included, { type: 'user', id: { uuid: userId } });

      const isScreened = get(included, 'attributes.profile.publicData.screened');
      const displayName = get(included, 'attributes.profile.displayName');

      const obj = {
        listId: list.id.uuid,
        title: list.attributes.title,
        imageUrl,
        isScreened: isScreened || false,
        displayName: displayName || '',
        distance: distance ? parseFloat(((distance / 1000) * 0.621371).toFixed(2)) : false,
      };
      makersList.push(obj);
    });

    this.setState({ makers: makersList });
  }

  redirectToMakerListingFromCustomer = listing => {
    const { history } = this.props;
    const routes = routeConfiguration();
    // const url = `/l/${listing.title.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')}/${
    //   listing.listId
    // }`;

    //window.location.href = url;
    history.push(
      createResourceLocatorString(
        'ListingPage',
        routes,
        { id: listing.listId, slug: createSlug(listing.title) },
        {}
      )
    );
  };

  onMakerSelect = list => {
    this.setState({ selectedMaker: list, disabledButton: false });
    this.redirectToMakerListingFromCustomer(list);
  };

  render() {
    const {
      className,
      rootClassName,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      onManageDisableScrolling,
      submitSignup,
      isAuthenticated,
      onCreateListingDraft,
      history,
    } = this.props;

    const { makers, selectedMaker, disabledButton } = this.state;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { title, about, description, publicData } = currentListing.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

    const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

    return (
      <div className={classes}>
        <div className={css.titleAndButton}>
          <h1 className={css.title}>Select your Maker</h1>
          <span>Here are Makers aligned with your project, sorted by location.</span>
        </div>

        <ul className={css.sections}>
          <li className={css.section}>
            <div className={css.sectionContentFirstChild}>
              <MakersList makers={makers} selectedListItem={this.onMakerSelect} />
            </div>
          </li>
        </ul>
        {/* <div className={css.titleAndButton}>
          <Button
            className={css.submitButton}
            onClick={() => {
              this.redirectToMakerListingFromCustomer(selectedMaker);
            }}
            disabled={disabledButton}
          >
            View Profile
          </Button>
        </div> */}
      </div>
    );
  }
}

CustomerMakerPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

CustomerMakerPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default CustomerMakerPanel;
