const { Logger } = require('aws-cloudwatch-log-browser');

export const awsLogger = (page, message, data) => {
    console.log(page,data,message)
    let config = { 
        logGroupName:  '/local/webapp', 
        logStreamName: 'webapp', 
        region: process.env.REACT_APP_AWS_REGION, 
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, 
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, 
        uploadFreq: 10000, 	
        local: false 		
    };
    const logger = new Logger(config);
    return logger.log({ type: page, details: message , data:data });
  };