import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { ListingLink, Modal, LinkTabNavHorizontal } from '../../components';
import { CustomerServiceForm, LoginForm, SignupForm } from '../../forms';
import CustomerServiceFormRestoration from '../../forms/CustomerServiceForm/CustomerServiceFormRestoration';
import CustomerServiceFormMadeToMeasure from '../../forms/CustomerServiceForm/customerServiceMadeToMeasureForm';
import config from '../../config.js';
import { customerData } from './config';
import _ from 'lodash';

import {
  isSignupEmailTakenError,
  isTooManyEmailVerificationRequestsError,
} from '../../util/errors';

// Create this file using EditListingDescriptionPanel.module.css
// as a template.
import css from './CustomerServicePanel.module.css';

const CustomerServicePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    selectedProject,
    onManageDisableScrolling,
    submitSignup,
    isAuthenticated,
    submitLogin,
    authInProgress,
    isSignupModal,
    setSignupModal,
    loginError,
    signupError,
    setBookingData,
    setCollectFromHome,
    bookingData,
    collectFromHome,
    isItemModal,
    setItemModal,
    deleteBookingItem,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  // const [project, setproject] = useState();
  const project = selectedProject ? selectedProject : 'altration';
  let [showForm, setShowForm] = useState(true);
  let [projectData, setprojectData] = useState([]);

  let [signupForm, setSignupForm] = useState(true);

  let [valuesToSubmit, setValuesToSubmit] = useState(false);

  try {
    useEffect(() => {
      Object.keys(customerData).forEach(function(key) {
        if (key === project) {
          let projectData = [];
          projectData = customerData[key];
          setprojectData(projectData);
          setShowForm(false);
        }
      });
    }, []);
  } catch (e) {
    console.log(e);
  }
  const capacityOptions = findOptionsForSelectFilter('capacity', config.custom.filters);

  const handleSubmitSignup = async values => {
    values.userType = 'customer';
    const { fname, lname, ...rest } = values;
    const { submitSignup } = props;
    const params = { firstName: fname.trim(), lastName: lname.trim(), ...rest };
    const isLogin = await submitSignup(params);
    if (isLogin && valuesToSubmit) {
      props.onSubmit(valuesToSubmit);
    }
  };

  const handleSubmitLogin = async values => {
    const { submitLogin } = props;
    const isLogin = await submitLogin(values);
    if (isLogin && valuesToSubmit) {
      props.onSubmit(valuesToSubmit);
    }
  };

  const loginErrorMessage = (
    <div className={css.error}>
      'The email and password you entered did not match our records. Please double-check and try
      again.'
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError)
        ? 'An account with this email address already exists. Try logging in instead.'
        : 'Signup failed. Please make sure all the information you entered is correct and try again.'}
    </div>
  );

  const errorMessage = (error, message) => (error ? message : null);

  const loginOrSignupError = signupForm
    ? errorMessage(signupError, signupErrorMessage)
    : errorMessage(loginError, loginErrorMessage);

  const authenticationForms = (
    <div className={css.content}>
      <div className={css.tabs}>
        <button
          type="button"
          className={signupForm ? css.bntBorder : css.noBtnBorder}
          onClick={() => setSignupForm(true)}
        >
          <h1 className={css.tab}>Signup</h1>
        </button>
        <button
          type="button"
          className={!signupForm ? css.bntBorder : css.noBtnBorder}
          onClick={() => setSignupForm(false)}
        >
          <h1 className={css.tab}>Login</h1>
        </button>
      </div>

      {loginOrSignupError}

      {signupForm ? (
        <SignupForm
          className={css.signupForm}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          // onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          customer="yes"
        />
      ) : (
        <LoginForm
          className={css.loginForm}
          onSubmit={handleSubmitLogin}
          inProgress={authInProgress}
        />
      )}
    </div>
  );

  return (
    <div className={classes}>
      {project == 'altration' && (
        <>
          <h1 className={css.title}>Choose your items</h1>
          {/* <span className={css.subTitle}>
            Choose your items and then select the type of service.
          </span> */}
          <CustomerServiceForm
            className={css.form}
            //initialValues={{ capacity: publicData.capacity }}
            onSubmit={values => {
              const updateValues = {
                publicData: {
                  values,
                  serviceKey: 'altration',
                  userType: 'customer',
                  collectFromHome: collectFromHome,
                },
              };

              if (!isAuthenticated) {
                setSignupModal(true);
                setValuesToSubmit(updateValues);
              } else {
                onSubmit(updateValues);
              }
            }}
            selectProjectData={projectData}
            showForm={showForm}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            updated={panelUpdated}
            updateError={errors.updateListingError}
            updateInProgress={updateInProgress}
            capacityOptions={capacityOptions}
            onManageDisableScrolling={onManageDisableScrolling}
            setBookingData={setBookingData}
            setCollectFromHome={setCollectFromHome}
            bookingData={bookingData}
            collectFromHome={collectFromHome}
            setItemModal={setItemModal}
            isItemModal={isItemModal}
            deleteBookingItem={deleteBookingItem}
          />
        </>
      )}
      {project == 'madeToMeasure' && (
        <>
          <h1 className={css.title}>What bespoke service are you looking for?</h1>
          <span className={css.subTitle}>All services are priced upon request.</span>
          <CustomerServiceFormMadeToMeasure
            className={css.form}
            onSubmit={values => {
              const updateValues = {
                publicData: {
                  values,
                  serviceKey: 'madeToMeasure',
                  userType: 'customer',
                },
              };
              if (!isAuthenticated) {
                setSignupModal(true);
                setValuesToSubmit(updateValues);
              } else {
                onSubmit(updateValues);
              }
            }}
            selectProjectData={projectData}
            showForm={showForm}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            updated={panelUpdated}
            updateError={errors.updateListingError}
            updateInProgress={updateInProgress}
            capacityOptions={capacityOptions}
            bookingData={bookingData}
            setBookingData={setBookingData}
            deleteBookingItem={deleteBookingItem}
            collectFromHome={collectFromHome}
            setCollectFromHome={setCollectFromHome}
          />
        </>
      )}
      {project == 'Repair' && (
        <>
          <h1 className={css.title}>What bespoke service are you looking for?</h1>
          <span className={css.subTitle}>All services are priced upon request.</span>
          <CustomerServiceFormRestoration
            className={css.form}
            onSubmit={values => {
              const updateValues = {
                publicData: {
                  values,
                  serviceKey: 'Repair',
                  userType: 'customer',
                },
              };
              if (!isAuthenticated) {
                setSignupModal(true);
                setValuesToSubmit(updateValues);
              } else {
                onSubmit(updateValues);
              }
            }}
            selectProjectData={projectData}
            showForm={showForm}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            updated={panelUpdated}
            updateError={errors.updateListingError}
            updateInProgress={updateInProgress}
            capacityOptions={capacityOptions}
            bookingData={bookingData}
            setBookingData={setBookingData}
            deleteBookingItem={deleteBookingItem}
            collectFromHome={collectFromHome}
            setCollectFromHome={setCollectFromHome}
          />
        </>
      )}
      <Modal
        id="Page.signUp"
        isOpen={isSignupModal}
        onClose={() => setSignupModal(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>{authenticationForms}</div>
      </Modal>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

CustomerServicePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

CustomerServicePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default CustomerServicePanel;
