export const customerData = {
  alteration: {
    key1: {
      heading: 'Casual clothing',
      subheading: ['Casual clothing (eg. trousers, tops)'],
      price: ['£2'],
      quantity: [
        { key: '5 q', label: '5' },
        { key: '10 q', label: '10' },
        { key: '20 q', label: '20' },
        { key: '30 q', label: '30' },
      ],
    },
    key2: {
      heading: 'Formal clothing',
      subheading: ['Suit', 'Evening dress', 'Bride or Bredesmaid'],
      price: ['£2', '£4', '£6'],
      quantity: [
        { key: '5 q', label: '5' },
        { key: '10 q', label: '10' },
        { key: '20 q', label: '20' },
        { key: '30 q', label: '30' },
      ],
    },
  },
  restoration: {
    key1: {
      heading: 'Casual clothing',
      subheading: ['Casual clothing (eg. trousers, tops)'],
      price: ['£2'],
      quantity: [
        { key: '5 q', label: '5' },
        { key: '10 q', label: '10' },
        { key: '20 q', label: '20' },
        { key: '30 q', label: '30' },
      ],
    },
    key2: {
      heading: 'Formal clothing',
      subheading: ['Suit', 'Evening dress', 'Bride or Bredesmaid'],
      price: ['£2', '£4', '£6'],
      quantity: [
        { key: '5 q', label: '5' },
        { key: '10 q', label: '10' },
        { key: '20 q', label: '20' },
        { key: '30 q', label: '30' },
      ],
    },
    key3: {
      heading: 'Activewear and Lingerie',
      subheading: ['Activewear'],
      price: ['£2'],
      quantity: [
        { key: '5 q', label: '5' },
        { key: '10 q', label: '10' },
        { key: '20 q', label: '20' },
        { key: '30 q', label: '30' },
      ],
    },
  },
};
