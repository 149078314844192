import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ValidationError.module.css';

/**
 * This component can be used to show validation errors next to form
 * input fields. The component takes the final-form Field component
 * `meta` object as a prop and infers if an error message should be
 * shown.
 */
const ValidationError = props => {
  const { rootClassName, className, fieldMeta, showPopup, location } = props;
  const { touched, error } = fieldMeta;
  const classes = classNames(rootClassName || css.root, className);
//But we’re working on it! <a style={{fontWeight: 500}} href="https://docs.google.com/forms/d/e/1FAIpQLSeYlEkV5Yewxx2gRmBLazgD9PEEUySalFCCNbWV6m9C_v1PTA/viewform" target="_blank">Sign up to our waitlist here</a> and we'll be in touch as soon as we're available in your area
  if (showPopup == true) {
    return (
      <div className={css.locationCard}>
        <h2 className={css.errorheading}>Great news!</h2>
        <p className={css.para}>
          Postal services are now available for your area
        </p>
      </div>
    );
  } else if (touched && !location && error) {
    return <div className={classes}>{error}</div>;
  } else {
    return null;
  }

  //return touched && error ? <div className={classes}>{error}</div> : null;
};

ValidationError.defaultProps = { rootClassName: null, className: null };

const { shape, bool, string } = PropTypes;

ValidationError.propTypes = {
  rootClassName: string,
  className: string,
  fieldMeta: shape({
    touched: bool.isRequired,
    error: string,
  }).isRequired,
};

export default ValidationError;
