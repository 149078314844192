import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { ListingLink } from '../../components';
import { EditListingProjectForm } from '../../forms';
import config from '../../config.js';
import { customerData } from './config';
import _ from 'lodash';

// Create this file using EditListingDescriptionPanel.module.css
// as a template.
import css from './EditListingProjectPanel.module.css';

const EditListingProjectPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const [project, setproject] = useState('alteration');
  let [showForm, setShowForm] = useState(true);
  let [projectData, setprojectData] = useState([]);

  try {
    useEffect(() => {
      Object.keys(customerData).forEach(function(key) {
       if(key === project) {
         let projectData = [];
         projectData = customerData[key];
         setprojectData(projectData);
         setShowForm(false);
       }
      });
    }, []);
  } catch (e) {
    console.log(e);
  }
  const panelTitle = currentListing.id ? (
    <FormattedMessage
      id="EditListingCapacityPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingCapacityPanel.createListingTitle" />
  );
  const capacityOptions = findOptionsForSelectFilter('capacity', config.custom.filters);

  return (
    <div className={classes}>
      <h1 className={css.title}>Alteration services</h1>
      <span className={css.subTitle}>Add your items and continue to maker list</span>
      <EditListingProjectForm
        className={css.form}
        initialValues={{ capacity: publicData.capacity }}
        onSubmit={values => {
          const { capacity } = values;
          const updateValues = {
            publicData: {
              capacity,
            },
          };
          onSubmit(updateValues);
        }}
        selectProjectData={projectData}
        showForm={showForm}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
        capacityOptions={capacityOptions}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingProjectPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingProjectPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingProjectPanel;
