import React, { useState, useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  FieldCheckbox,
} from '../../components';

import css from './EditListingLocationForm.module.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressRequiredMessageAddress = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequiredAddress',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      const buildingMessage = intl.formatMessage(
        { id: 'EditListingLocationForm.building' },
        { optionalText: optionalText }
      );
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.buildingPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const [toggleValue, settoggleValue] = useState(false);

      function showMessage(value) {
        settoggleValue(!value);
      }

      function handleLogout(e) {
        e.preventDefault();

        const { onLogout, history } = props;
        onLogout().then(() => {
          const path = pathByRouteName('LandingPage', routeConfiguration());

          // In production we ensure that data is really lost,
          // but in development mode we use stored values for debugging
          if (config.dev) {
            history.push(path);
          } else if (typeof window !== 'undefined') {
            window.location = path;
          }

          console.log('logged out'); // eslint-disable-line
        });
      }
      return (
        <Form className={classes} onSubmit={toggleValue ? handleLogout : handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <label className={css.removeMargin}>Location</label>
          <p className={css.removeMargin}>
            If you are working from a location different to your home then please enter this
            address instead
          </p>
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder="Enter your postcode"
            useDefaultPredictions={false}
            format={identity}
            showMessage={showMessage}
            valueFromForm={values.location}
            formType="maker"
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          {/* {!toggleValue && (
            <FieldTextInput
              className={css.building}
              type="text"
              name="building"
              id="building"
              label="Please enter your full home address so that we can match you with nearby Customers. If you are working from a location different to your home address then please enter this address instead"
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessageAddress)
              )}
              placeholder={buildingPlaceholderMessage}
            />
          )} */}

          {toggleValue && (
            <div>
              <p>
                Currently, The Seam operates in London only. But don’t worry, we are expanding
                across the rest of the UK very soon! If you live outside of London then your profile
                will be saved from this point and we will contact you as soon as we expand to your
                area
              </p>
            </div>
          )}
          {!toggleValue && (
            <FieldCheckbox
              id="studio"
              name="havingStudio"
              label="Check this box if you have access to a studio where customers can visit you for fittings, this includes home studios"
              value={true}
            />
          )}

          {!toggleValue && (
            <FieldCheckbox
              id="inStore"
              name="inStore"
              label="Check this box if you are currently working at a Care + Repair Station sponsored bt The Seam"
              value={true}
            />
          )}

          {toggleValue ? (
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={false}
              ready={submitReady}
            >
              {'Thank you'}
            </Button>
          ) : (
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          )}
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
